// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#questionGrid p {
  margin: 3px 0px 0px 0px !important;
}

#questionGrid label {
  margin: 3px 0px 0px -14px !important;
  font-size: 1rem;
}

.skip_button:hover {
  background: #214476;
  color: #ffffff;
}

#questionOne,
#questionTwo,
#questionThree,
#questionFour,
#questionFive {
  padding-bottom: 14px;
}

.boldText {
  color: #4d4d4d;
}

#questionDiv div p {
  font-weight: 700;
}

.mfa_loadingSpinnerDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mfa_loadingSpinner {
  padding: 10%;
}

#continue_button {
  margin: auto;
}

.modalButton {
  width: 10%;
  padding: 10px 40px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/MultiFactorAuthentication/mfa.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;;;;;EAKE,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,6BAA6B;AAC/B","sourcesContent":["#questionGrid p {\n  margin: 3px 0px 0px 0px !important;\n}\n\n#questionGrid label {\n  margin: 3px 0px 0px -14px !important;\n  font-size: 1rem;\n}\n\n.skip_button:hover {\n  background: #214476;\n  color: #ffffff;\n}\n\n#questionOne,\n#questionTwo,\n#questionThree,\n#questionFour,\n#questionFive {\n  padding-bottom: 14px;\n}\n\n.boldText {\n  color: #4d4d4d;\n}\n\n#questionDiv div p {\n  font-weight: 700;\n}\n\n.mfa_loadingSpinnerDiv {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.mfa_loadingSpinner {\n  padding: 10%;\n}\n\n#continue_button {\n  margin: auto;\n}\n\n.modalButton {\n  width: 10%;\n  padding: 10px 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiSlider-markLabel {
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  font-family: 'Muli', sans-serif !important;
  color: #595959 !important;
}

#discrete-slider-always {
  font-size: 1.078rem !important;
}

#discrete-slider-always-amount {
  font-size: 1.438rem !important;
  color: #134ca7 !important;
  font-weight: 700 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormsUI/Slider/Slider.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,0CAA0C;EAC1C,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".MuiSlider-markLabel {\n  font-weight: 400 !important;\n  font-size: 0.938rem !important;\n  font-family: 'Muli', sans-serif !important;\n  color: #595959 !important;\n}\n\n#discrete-slider-always {\n  font-size: 1.078rem !important;\n}\n\n#discrete-slider-always-amount {\n  font-size: 1.438rem !important;\n  color: #134ca7 !important;\n  font-weight: 700 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

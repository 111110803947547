import React from 'react'
import TextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'

const styles = {
  blueTextField: {
    '& .MuiOutlinedInput-input': {
      color: '#013474',
      textAlign: 'center',
      padding: '0px',
      height: '70px',
      fontSize: '2rem',
      width: '70px',
      maxWidth: '70px',
      fontWeight: 'bold',
      '@media (max-width: 480px)': {
        width: '35px',
        height: '35px',
        fontSize: '1.3rem',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#416797',
      borderWidth: 1.8,
      borderRadiuse: '0.5rem',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#416797',
    },
  },
}

const OtpField = withStyles(styles)(({ classes, ...props }) => (
  <TextField {...props} className={classes.blueTextField} variant="outlined" />
))

export default OtpField

/*#################################################################################################################

File Name           :    Email/index.js
Component Name      :    Email
Functionality       :    To use this component to get only valid Email address.

#################################################################################################################*/
import { FormLabel, Grid } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import dynamicStyles from '../OutlineStyles'

//Initializing EmailWrapper component
const EmailWrapper = ({ name, suffix, label, materialProps, design, id, disablePaste, ...otherProps }) => {
  //Basic Configuration for Email field

  const useStyles = makeStyles((theme) => ({
    textFieldWrapper: {
      width: 'inherit',
      padding: '0 10px',
    },
    textFieldWrapperNew: {
      width: '100%',
    },
    formLabelStyle: {
      padding: '2px 0',
    },
  }))

  const classes = useStyles()
  const borderClasses = dynamicStyles()

  const configTextfield = {
    name: name,
    label: design === 'new' ? '' : label,
    fullWidth: true,
    classes: {
      root: borderClasses.dynamic,
    },
    InputProps: suffix
      ? {
          endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
        }
      : {},
    ...otherProps,
  }

  const [, setValues] = useState('')
  const handleChange = (event) => {
    setValues(event.target.value.trim() + suffix)
  }
  const disablePasteOption = (event) => {
    if (disablePaste) {
      event.preventDefault()
    }
  }

  return (
    <Grid className={design === 'new' ? classes.textFieldWrapperNew : classes.textFieldWrapper}>
      <>
        {design === 'new' ? (
          <Grid className={classes.formLabelStyle}>
            <FormLabel>{label}</FormLabel>
          </Grid>
        ) : (
          <> </>
        )}
        <TextField
          id={id}
          fullWidth={true}
          variant={design === 'new' ? 'outlined' : 'standard'}
          inputProps={materialProps}
          onChange={handleChange}
          onCut={disablePasteOption}
          onCopy={disablePasteOption}
          onPaste={disablePasteOption}
          {...configTextfield}
        />
      </>
    </Grid>
  )
}

EmailWrapper.propTypes = {
  name: PropTypes.string,
  suffix: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  design: PropTypes.string,
  disablePaste: PropTypes.bool,
  materialProps: PropTypes.object,
}

export default EmailWrapper

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.close {
  height: 0;
  transition: 0.5s;
  overflow: hidden;
  font-size: unset !important;
  color: unset !important;
  font-weight: unset !important;
}
.MuiFormLabel-root,
.MuiTypography-root,
.MuiFormHelperText-root,
.placeholder,
.MuiInputBase-root,
.MuiTableCell-root {
  font-family: 'Muli', sans-serif !important;
}
.MuiPaper-rounded {
  border-radius: 10px !important;
}
.placeholder {
  font-size: 10px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,0CAA0C;EAC1C,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;EAC3B,uBAAuB;EACvB,6BAA6B;AAC/B;AACA;;;;;;EAME,0CAA0C;AAC5C;AACA;EACE,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB;AACA;EACE,+EAA+E;AACjF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Muli', sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.close {\n  height: 0;\n  transition: 0.5s;\n  overflow: hidden;\n  font-size: unset !important;\n  color: unset !important;\n  font-weight: unset !important;\n}\n.MuiFormLabel-root,\n.MuiTypography-root,\n.MuiFormHelperText-root,\n.placeholder,\n.MuiInputBase-root,\n.MuiTableCell-root {\n  font-family: 'Muli', sans-serif !important;\n}\n.MuiPaper-rounded {\n  border-radius: 10px !important;\n}\n.placeholder {\n  font-size: 10px;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { createStyles, makeStyles } from '@mui/styles'
import overlayImage from '../../../assets/images/mariner_overlay_Logo.png'
import { Hidden } from '@mui/material'
//Styling Part
const useStylesMFA = makeStyles((theme) =>
  createStyles({
    arrowBack: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      padding: '1.5rem',
    },

    forgotPasswordRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },
    forgotPasswordContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      margin: '3rem auto',
    },
    forgotPasswordPaper: {
      backgroundImage: `url(${overlayImage})`,
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '20%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
    },
    mainContentArea: {
      padding: '3rem 1rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      // alignItems: 'center',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: '400',
      color: '#000',
      fontFamily: "'Muli', sans-serif !important",
    },
    subTitle: {
      textAlign: 'center',
      fontSize: '1.25rem',
      fontWeight: '400',
      color: '#000',
      fontFamily: "'Muli', sans-serif !important",
    },
    directiveText: {
      textAlign: 'center',
      color: '#000',
      fontSize: '1.06rem',
      fontFamily: "'Muli', sans-serif !important",
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1rem 2rem 0 0rem',
    },
    inputLabel: {
      fontSize: '.90rem',
      color: '#585858',
      bottom: '.5rem',
      fontFamily: "'Muli', sans-serif !important",
      width: '100%',
    },
    dialogRoot: {
      margin: '0 10px',
    },

    dialogPaper: {
      // This style is for the Paper component inside Dialog
      width: '50%', // Off on Mobile
      height: 'auto',
      maxWidth: 'none',
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    dialogContainer: {
      width: '40rem', // Off on Mobile
      height: 'auto',

      padding: '0 0 2rem 0',
      justifyContent: 'center',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    centeredContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },

    helperText: {
      textDecoration: 'none',
      color: '#013474',
    },
    radioGroup: {
      padding: '0 0 .25rem 0',
      color: '#585858',
    },
    customRadio: {
      '& .MuiSvgIcon-root': {
        color: '#585858',
        width: '20px',
        height: '20px',
      },
    },
    customLabelField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1.5px solid #013474',
          // custom color
        },
        '&:hover fieldset': {
          borderColor: '#013474',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#013474',
        },
      },
    },
    errorValidationColumn: {
      padding: '0',
    },
    disclosure: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '2rem auto',
      flexDirection: 'column',
    },
    securityQuestion: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      fontSize: '1.25rem',
      fontWeight: '400',
      color: '#000',
      fontFamily: "'Muli', sans-serif !important",
    },
    resendForm: {
      padding: '2rem 0 0rem 0',
    },
    subText: {
      padding: '1rem 0 0rem 0',
    },
    disclosureText: {
      textAlign: 'center',
      lineHeight: '1.5',
      fontSize: '0.938rem',
      color: '#000',
      fontFamily: "'Muli', sans-serif !important",
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '3rem 0',
    },
    footerText: {
      fontSize: '0.938rem',
      color: '#585858',
      margin: '.5rem auto',
      fontFamily: "'Muli', sans-serif !important",
    },
    bottomNoteText: {
      fontSize: '0.938rem',
      margin: '-3rem auto',
      textAlign: 'center',
    },
    footerLink: {
      color: '#0F4EB3',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '0.938rem',
      textDecoration: 'none',
      fontFamily: "'Muli', sans-serif !important",
    },

    passwordResetModalButtonIcon: {
      display: 'none',
    },
    rotatingIcon: {
      animation: 'spin 2s linear infinite',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    autoRenew: {
      animation: '$spin 1s linear infinite',
    },

    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },

    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'noWrap',

      textAlign: 'center',
    },
    otpContainer: {
      justifyContent: 'center',
      flexWrap: 'unset',
      padding: '2rem 0 1rem 0',
    },
    /* CSS for the radioSpacing class */
    radioSpacing: {
      marginBottom: '1.5rem',
    },
    smallRadioButton: {
      margin: '10px 0px',
      '& svg': {
        width: '20px !important',
        height: '20px !important',
      },
    },
    divHide: {
      display: 'none',
    },
    divShow: {
      display: 'show',
    },
    twoStepWrap: {
      margin: 'auto',
      // maxWidth: "630px",
      minHeight: '88vh',
      alignItems: 'center',
      width: '100%',
    },
    headingTextWrap: {
      position: 'relative',
    },
    backArrow: {
      position: 'absolute',
      left: '0',
      top: '0',
      padding: '4px 0px',
      width: '32px',
      height: '32px',
    },
    alignCenterDiv: {
      textAlign: 'center',
    },
    yellowBackArrow: {
      color: '#ffbc23',
    },
    otpPaper: {
      idth: '100%',
      padding: '20px 40px',
      margin: '40px 20px',
      boxSizing: 'border-box',
      height: 'fit-content',
      boxShadow: 'rgb(61 61 61 / 10%) 0px 8px 44px 0px',
    },
    twoStepPaper: {
      width: '100%',
      padding: '20px 40px',
      margin: '40px 20px',
      boxSizing: 'border-box',
      height: 'fit-content',
      boxShadow: 'rgb(61 61 61 / 10%) 0px 8px 44px 0px',
      '@media (max-width: 520px)': {
        padding: '30px  20px',
      },
    },
    twoStepHeading: {
      textAlign: 'center',
      fontSize: '2rem',
      margin: '0.82rem 11% 0px 11%',
      color: '#171717',
    },
    twoStepParagraph: {
      width: '100%',
      textAlign: 'justify',
      margin: '15px 0px',
      fontSize: '1.4rem',
      color: '#595959',
    },
    securityCodeText: {
      fontSize: '1rem',
      color: '#595959',
    },
    securityQuestions: {
      fontSize: '1rem',
      color: '#595959',
    },
    radioButtonwrap: {
      width: '100%',
      padding: '0px',
      boxSizing: 'border-box',
    },
    radioGroupWrap: {
      display: 'flex',
      flexDirection: 'column',
    },
    nextButtonGrid: {
      justifyContent: 'center',
      margin: '15px 0px',
    },
    otpWrap: {
      marginBottom: '25px',
    },
    otpNumber: {
      padding: '0px 5px',
      textAlign: 'center',
    },
    securityQuestionsInput: {
      padding: '0px 5px',
      textAlign: 'left',
      margin: '15px 0',
    },
    selectSecurityQuestionsInput: {
      padding: '0px 5px',
      textAlign: 'left',
      margin: '5px 0',
    },
    resetText: {
      fontSize: '0.844rem',
      textAlign: 'center',
      margin: '30px 0px 0px 0px',
    },
    securityCubText: {
      fontSize: '0.938rem',
      textAlign: 'center',
      margin: '15px 0px',
      color: '##595959',
    },
    loadingOn: {
      opacity: 0.55,
      pointerEvents: 'none',
    },
    loadingOnWithoutBlur: {
      pointerEvents: 'none',
    },
    loadingOff: {
      opacity: 1,
      pointerEvents: 'initial',
    },
    button_div: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
    button_space: {
      color: 'black',
      width: 'auto',
      height: '36px',
      padding: '0px 30px',
      background: '#FFBC23 !important',
      whiteSpace: 'nowrap',
      borderRadius: '50px',
      margin: '5px',
      textTransform: 'none',
    },
    skip_button: {
      fontFamily: 'Muli, sans-serif ',
      color: '#1B488A',
      border: '1px solid  #1B488A',
      width: 'auto',
      height: '36px',
      padding: '0px 30px',
      background: 'transparent',
      whiteSpace: 'nowrap',
      borderRadius: '50px',
      margin: '5px',
      textTransform: 'none',
    },
    test: {
      margin: '0 5vw',
      [theme.breakpoints.down('sm')]: {
        margin: '0 0vw',
      },
    },
    inputLabelWrap: {
      whiteSpace: 'break-spaces',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  })
)

export { useStylesMFA }

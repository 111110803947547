import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TextField, Typography, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { ButtonPrimary, OtpField } from '../../FormsUI'
import './MultiFactorAuthentication.css'
import { useStylesMFA } from './Style'
import { toast } from 'react-toastify'
import { VerifyLoginPassCode, SendLoginPassCode } from './../../Controllers/MFAController'
import { useNavigate, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import CheckLoginTimeout from '../../Layout/CheckLoginTimeout'
import CheckLoginStatus from '../../App/CheckLoginStatus'
import VerifyAccountLogo from '../../../assets/icon/confirmation 3.png'
import Messages from '../../../assets/data/globalMessages.json'
import { useFingerprint } from '../../../hooks/useFingerprint'
import { updateMFAStatusAsCompleted, updateUserIsMFAChallenged } from '../../Controllers/CommonController'

const MultiFactorAuthenticationOTP = () => {
  const otpLocation = useLocation()
  const navigate = useNavigate()
  const classes = useStylesMFA()
  const loginToken = JSON.parse(Cookies.get('token') ? Cookies.get('token') : '{ }')
  const [currentCount, setCount] = useState(10)
  const customerPhoneNumber = otpLocation?.state?.phoneNumber
  const customerEmail = Cookies.get('email')
  const { visitorId } = useFingerprint()
  const [disabledButton, setDisabledButton] = useState(false)
  const [otpValue, setOtpValue] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
  const isSecurityQuestionSaved = otpLocation?.state?.mfaQueries?.mfaDetails?.securityQuestionsSaved ?? false
  const [focusedInput, setFocusedInput] = useState(0)
  useEffect(() => {
    let otpSkipCookie = Cookies.get('otpSkip')
    if (!otpLocation?.state?.mfaQueries) {
      navigate('/customers/accountOverview')
    } else if (otpSkipCookie) {
      navigate('/MFA')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = () => setCount(currentCount - 1)
    if (currentCount <= 0) {
      return
    }
    const id = setInterval(timer, 1000)
    return () => clearInterval(id)
  }, [currentCount])
  const checkEnteredOTP = (OTPObject) => {
    for (let key in OTPObject) {
      if (OTPObject[key] === '') return true
    }
    return false
  }
  // const handleChange = (event) => {
  //   const { name, value } = event.target
  //   setOtpValue((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }))
  // }
  // const focusInputBox = (event) => {
  //   if (event.key === 'Delete' || event.key === 'Backspace') {
  //     const next = event.target.tabIndex - 1
  //     if (next > 0) {
  //       event.target.form.elements[next].focus()
  //     }
  //   } else if (event.key !== 'Tab') {
  //     const next = event.target.tabIndex
  //     if (next < 6 && event.target.value) {
  //       event.target.form.elements[next + 1].focus()
  //     }
  //   }
  // }

  // Enhanced handleChange to handle digit input and focus shift

  const handleChange = (event) => {
    const { name, value } = event.target
    const numericValue = value.replace(/[^0-9]/g, '') // Allow only numbers
    const fieldIndex = parseInt(name.replace('otp', ''), 10)

    setOtpValue((prevState) => ({
      ...prevState,
      [name]: numericValue,
    }))

    if (numericValue && fieldIndex < 6) {
      setTimeout(() => {
        const nextInput = document.getElementById(`otpNumber${fieldIndex + 1}`)
        if (nextInput) nextInput.focus()
      }, 0)
    }
  }

  const handleBackspace = (event) => {
    const { name, value } = event.target
    const fieldIndex = parseInt(name.replace('otp', ''), 10)

    if (event.key === 'Backspace' && !value && fieldIndex > 1) {
      const prevField = `otp${fieldIndex - 1}`
      setOtpValue((prevState) => ({
        ...prevState,
        [prevField]: '',
      }))
      setTimeout(() => {
        const prevInput = document.getElementById(`otpNumber${fieldIndex - 1}`)
        if (prevInput) prevInput.focus()
      }, 0)
    }
  }

  const getPasscode = (otp) => {
    let passCode = ''
    for (let key in otp) {
      passCode += otp[key]
    }
    return passCode
  }

  const handleClickSubmit = async () => {
    setDisabledButton(true)
    let enteredOTP = getPasscode(otpValue)
    let response = await VerifyLoginPassCode(enteredOTP, customerEmail, visitorId, customerPhoneNumber)
    if (response?.data?.statusCode === 200) {
      toast.success(response.data?.Message)
      if (isSecurityQuestionSaved) {
        // redirect to Account overview
        updateMFAStatusAsCompleted()
        updateUserIsMFAChallenged()
        if (Cookies.get('forceResetPassword') === 'true') {
          const email = Cookies.get('email')
          navigate('/resetpassword', { state: { Email: email } })
        } else {
          navigate('/customers/accountOverview')
        }
      } else {
        // redirect to select security question page
        Cookies.set('otpSkip', 'skipOtpPage')
        navigate('/MFA-SelectSecurityQuestions', { state: { currentFlow: true, preVerification: true } })
      }
    } else {
      if (
        response.data?.Message?.includes('account has been locked') ||
        response.data?.errorMessage?.includes('account has been locked')
      ) {
        toast.error(Messages.Contact_Support)
        navigate('/login')
      } else {
        setOtpValue({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
        toast.error(response.data?.Message ?? response.data?.errorMessage)
      }
    }
    setDisabledButton(false)
  }

  const resendOTP = async () => {
    let response = await SendLoginPassCode(customerPhoneNumber)

    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log('resent response', response) //Left this console log intentionally for QA
    }

    if (response?.data?.passcodeInTextMessage) {
      toast.success('Successfully sent passcode')
      setCount(60)
    } else {
      toast.error(response.data?.Message)
    }
  }

  const backToVerificationStep = () => {
    navigate(-1)
  }

  const getOTPTextField = (id, name, tabIndex) => {
    const opt = Object.keys(otpValue)
    return (
      <Grid item id={`wrap${id}`} style={{ margin: '0 5px', position: 'relative' }}>
        <OtpField
          id={`otpNumber${tabIndex}`}
          name={name}
          value={otpValue[name]}
          className={classes.otpNumber}
          lable="OTP"
          type="text"
          variant="outlined"
          onChange={handleChange}
          inputProps={{ tabIndex: tabIndex, maxLength: 1 }}
          onKeyDown={handleBackspace}
          // onKeyUp={(event) => focusInputBox(event)}
          // onInput={(e) => {
          //   e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
          // }}
        />
      </Grid>
    )
  }
  return (
    <div>
      <CheckLoginTimeout />
      {loginToken.isLoggedIn && otpLocation?.state?.mfaQueries ? (
        <>
          <div data-testid="passcode-verification-container" className={classes.forgotPasswordRoot}>
            <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
              <Paper className={classes.forgotPasswordPaper}>
                <Grid item xs={12} md={7} gap={2} className={classes.mainContentArea}>
                  <Box display="flex" flexDirection="column" gap={2} alignItems="center">
                    <Grid container className={classes.rotatingIcon}>
                      <img src={VerifyAccountLogo} alt="passwordlogo" className="spinAnimation" />
                    </Grid>
                    <Typography className={classes.title}>Security Code</Typography>

                    {/* <IconButton className={classes.backArrow} onClick={backToVerificationStep}>
                      <ArrowBackIcon className={classes.yellowBackArrow} />
                    </IconButton> */}
                  </Box>
                  <Typography className={classes.directiveText}>
                    Enter the code sent to your mobile <span>{`(***) ***-${customerPhoneNumber.substr(-4)}`} </span>.
                  </Typography>
                  <Typography className={classes.disclosureText} style={{ color: '#585858', marginTop: '-8px' }}>
                    Code is valid for 15 minutes.
                  </Typography>
                  {/* {${customerPhoneNumber.substr(-4)} */}
                  <form>
                    <Grid
                      justifyContent={'center'}
                      flexWrap={'unset'}
                      className={classes.otpContainer}
                      container
                      item
                      id="otpFieldWrapper"
                      style={{ marginTop: '2.5rem' }}
                    >
                      {/* <Typography className={classes.twoStepParagraph}>Enter 6 digit security code</Typography> */}

                      {getOTPTextField('otpNumberOne', 'otp1', 1)}
                      {getOTPTextField('otpNumberTwo', 'otp2', 2)}
                      {getOTPTextField('otpNumberThree', 'otp3', 3)}
                      {getOTPTextField('otpNumberFour', 'otp4', 4)}
                      {getOTPTextField('otpNumberFive', 'otp5', 5)}
                      {getOTPTextField('otpNumberSix', 'otp6', 6)}
                    </Grid>
                    <div className={classes.formContainer}>
                      <Typography className={classes.footerText}>
                        If you do not receive the code within 2 minutes,{' '}
                        {currentCount > 0 ? (
                          <span className="blueColorLink" style={{ cursor: 'pointer' }}>
                            resend code
                          </span>
                        ) : (
                          <span onClick={resendOTP} style={{ cursor: 'pointer' }} className="blueColorLink">
                            Resend
                          </span>
                        )}
                        {currentCount > 0 ? ` (in 0:${currentCount} secs)` : ''}
                      </Typography>
                      <Grid item className={classes.footer}>
                        <ButtonPrimary
                          stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                          disabled={checkEnteredOTP(otpValue) || disabledButton}
                          onClick={handleClickSubmit}
                        >
                          Confirm
                        </ButtonPrimary>
                      </Grid>
                    </div>
                  </form>
                </Grid>
              </Paper>
            </Grid>
          </div>
        </>
      ) : (
        <CheckLoginStatus />
      )}
    </div>
  )
}

export default MultiFactorAuthenticationOTP

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#customeDialogBox .MuiDialog-container .MuiDialog-paper {
  margin: 20px !important;
}

@media print {
  .modalButton {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .dialogTitleWrap {
    padding-top: 35px !important;
  }
}

.mfaModelAction {
  text-align: center !important;
  justify-content: center;
  margin-bottom: 1rem;
}

.MFAModalButton {
  width: auto !important;
  border-radius: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormsUI/Popup/Popup.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["#customeDialogBox .MuiDialog-container .MuiDialog-paper {\n  margin: 20px !important;\n}\n\n@media print {\n  .modalButton {\n    display: none !important;\n  }\n}\n\n@media (max-width: 480px) {\n  .dialogTitleWrap {\n    padding-top: 35px !important;\n  }\n}\n\n.mfaModelAction {\n  text-align: center !important;\n  justify-content: center;\n  margin-bottom: 1rem;\n}\n\n.MFAModalButton {\n  width: auto !important;\n  border-radius: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

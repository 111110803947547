/*#################################################################################################################

File Name           :    Comments/index.js
Component Name      :    Comments
Functionality       :    Use this component for customers to type comments.

#################################################################################################################*/
import { FormLabel, Grid } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import dynamicStyles from '../OutlineStyles'

//Initializing CommentsWrapper component
const CommentsWrapper = ({
  name,
  value,
  suffix,
  label,
  materialProps,
  design,
  id,
  disablePaste,
  rows,
  character_limit,
  ...otherProps
}) => {
  //Basic Configuration for comment field

  const useStyles = makeStyles((theme) => ({
    textFieldWrapper: {
      width: 'inherit',
      padding: '0 10px',
    },
    textFieldWrapperNew: {
      width: '100%',
    },
    formLabelStyle: {
      padding: '2px 0',
    },
  }))

  const classes = useStyles()
  const borderClasses = dynamicStyles()

  const configTextfield = {
    name: name,
    label: design === 'new' ? '' : label,
    fullWidth: true,
    classes: {
      root: borderClasses.dynamic,
    },
    InputProps: suffix
      ? {
          endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
        }
      : {},
    ...otherProps,
  }

  const disablePasteOption = (event) => {
    if (disablePaste) {
      event.preventDefault()
    }
  }

  return (
    <Grid className={design === 'new' ? classes.textFieldWrapperNew : classes.textFieldWrapper}>
      <>
        {design === 'new' ? (
          <Grid className={classes.formLabelStyle}>
            <FormLabel>{label}</FormLabel>
          </Grid>
        ) : (
          <> </>
        )}
        <TextField
          id={id}
          fullWidth={true}
          variant={design === 'new' ? 'outlined' : 'standard'}
          inputProps={{
            maxLength: character_limit ?? 20,
          }}
          onCut={disablePasteOption}
          onCopy={disablePasteOption}
          onPaste={disablePasteOption}
          {...configTextfield}
          multiline
          rows={rows}
        />
      </>
    </Grid>
  )
}

CommentsWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  suffix: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  design: PropTypes.string,
  disablePaste: PropTypes.bool,
  materialProps: PropTypes.object,
  rows: PropTypes.string,
  character_limit: PropTypes.string,
}

export default CommentsWrapper

import APICall from '../lib/AxiosLib'
import globalMessages from '../../assets/data/globalMessages.json'
import ErrorLogger from '../lib/ErrorLogger'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
// Storage Utility
class StorageUtility {
  /**
   * Sets an item in local storage
   * @param {string} key - The key to set
   * @param {string} value - The value to set
   */
  static setItem(key, value) {
    try {
      localStorage.setItem(key, value)
    } catch (error) {
      ErrorLogger('Error setting item in storage:', error)
    }
  }

  /**
   * Gets an item from local storage
   * @param {string} key - The key to get
   * @returns {string|null} - The value of the key or null if not found
   */
  static getItem(key) {
    try {
      return localStorage.getItem(key)
    } catch (error) {
      ErrorLogger('Error getting item from storage:', error)
      return null
    }
  }

  /**
   * Removes an item from local storage
   * @param {string} key - The key to remove
   */
  static removeItem(key) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      ErrorLogger('Error removing item from storage:', error)
    }
  }
}

/**
 * Reusable function to standardize response handling for API calls
 *
 */
function handleApiResponse(response, includeData = false) {
  const errorMessage = globalMessages.Unable_to_process_your_request
  if (!response) {
    throw new Error(errorMessage)
  } else if (response.status === 200) {
    return includeData ? response : true // or just true if you don't need the data
  } else if (response.status === 400) {
    // check if response.error exists or if response.data.error exists or if response.data.error.message exists or if response.data.error.message is not empty string or if response.data.error.message is not null
    const errorMessage =
      response?.error?.message ||
      response?.data?.error ||
      response?.data?.message ||
      response?.data?.error?.message ||
      globalMessages.Unable_to_process_your_request
    throw new Error(errorMessage)
  } else {
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Generates a random string
 * @returns {string} - The generated random string
 */
const generateRandomString = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

/**
 * Generates a code verifier and stores it in local storage
 * @returns {string} - The generated code verifier
 */
const generateCodeVerifier = () => {
  const codeVerifier = generateRandomString()
  StorageUtility.setItem('codeVerifier', codeVerifier) // Storing the codeVerifier
  return codeVerifier
}

/**
 * Generates a code challenge from a code verifier
 * @param {string} verifier - The code verifier to generate the challenge from
 * @returns {Promise<string>} - A promise that resolves with the generated code challenge
 */
const generateCodeChallenge = async (verifier) => {
  const hash = sha256(verifier)
  const base64 = Base64.stringify(hash)
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

/**
 * Sends a request to reset a user's password
 * @param {string} email - The email of the user to reset the password for
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function requestPasswordReset(email) {
  try {
    const codeVerifier = generateCodeVerifier()
    const codeChallenge = await generateCodeChallenge(codeVerifier)
    const codeChallengeMethod = codeChallenge === codeVerifier ? 'plain' : 'S256' // Determining method based on whether hashing was used

    const payload = {
      email,
      code_challenge: codeChallenge,
      code_challenge_method: codeChallengeMethod, // using dynamic method
    }
    const url = 'request_password_reset'
    const param = ''
    const data = payload
    const method = 'POST'
    const addAccessToken = false

    const response = await APICall(url, param, data, method, addAccessToken)
    return handleApiResponse(response, true)
  } catch (error) {
    if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_request_password_reset_API, error)
      throw new Error(error)
    }
    ErrorLogger(globalMessages.Error_executing_request_password_reset_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Validates a magic link token
 * @param {string} token - The magic link token to validate
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function validateMagicLink() {
  try {
    // get token from url
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('activation_token') || ''
    if (!token) throw new Error('Invalid Session. Please try again.') // CLARIFY : Redirect to forgot-password?
    // const codeVerifier = StorageUtility.getItem('codeVerifier'); // Retrieving the codeVerifier
    const payload = {
      token,
    }
    const url = 'verify_user_password_reset_link'
    const param = ''
    const data = payload
    const method = 'POST'
    const addAccessToken = true

    const response = await APICall(url, param, data, method, addAccessToken)
    return handleApiResponse(response, true)
  } catch (error) {
    if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_validate_magic_link_API, error)
      throw new Error(error)
    }
    ErrorLogger(globalMessages.Error_executing_validate_magic_link_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Sends a request to generate an OTP
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function requestOTP(sessionID, phone) {
  let response
  try {
    if (!phone) throw new Error('Please select a phone number.')
    const payload = {
      sessionID: sessionID,
      phone_number: phone,
    }
    const url = 'request_otp'
    const param = ''
    const data = payload
    const method = 'POST'
    const addAccessToken = true
    response = await APICall(url, param, data, method, addAccessToken)

    return handleApiResponse(response, true)
  } catch (error) {
    if (response?.data?.Message.includes('Your phone number is invalid')) {
      ErrorLogger(globalMessages.Error_executing_request_otp_API, response.data.Message)
      throw new Error(response.data.Message)
    } else if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_request_otp_API, error)
      throw new Error(error)
    }
    ErrorLogger(globalMessages.Error_executing_request_otp_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Validates an OTP
 * @param {string} otp - The OTP to validate
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function validateOTP(otp, sessionID) {
  try {
    if (!otp || otp.length < 6) throw new Error('Please enter the 6 digit code sent to your phone.')
    const payload = {
      otp: otp,
      sessionID: sessionID,
    }
    const url = 'validate_otp'
    const param = ''
    const data = payload
    const method = 'POST'
    const addAccessToken = true
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_validate_otp_API, error)
      throw new Error(error)
    }
    ErrorLogger(globalMessages.Error_executing_validate_otp_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Validate User Recovery Question
 * @param {string} answer - The answer to validate
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function validateRecoveryAnswer(questionId, answer, sessionID) {
  try {
    if (!answer || !questionId) throw new Error('Something went wrong. Please try again.') // CLARIFY : Redirect to forgot-password?

    const payload = {
      questionId,
      answer,
      sessionID,
    }
    const url = 'validate_recovery_answer'
    const param = ''
    const data = payload
    const method = 'POST'
    const addAccessToken = true
    // Send Cookie and sessionID from cookie to API with request

    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_validate_recovery_answer_API, error)
      throw new Error(error)
    }
    ErrorLogger(globalMessages.Error_executing_validate_recovery_answer_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

/**
 * Resets a user's password
 * @param {string} password - The new password to set
 * @param {string} accessToken - The access token for the user's session
 * @returns {Promise<*>} - A promise that resolves with the response from the API or false if there was an error
 */
export async function resetPassword(password, sessionID) {
  try {
    // if (!password) throw new Error('Please enter password')
    // if (!accessToken) throw new Error('Your session has expired')
    // note : sessionID is the public 'key' the cookie secure on backend is used to decrypt
    const payload = {
      sessionID: sessionID, // TODO : Get sessionID from cookie
      password,
      // access_token: accessToken,
    }
    const url = 'reset_password'
    const param = ''
    const data = payload
    const method = 'POST'
    // const addAccessToken = true
    // Send Cookie and sessionID from cookie to API with request
    const response = await APICall(url, param, data, method)
    return handleApiResponse(response, false)
  } catch (error) {
    if (error !== null && error !== undefined) {
      ErrorLogger(globalMessages.Error_executing_reset_password_API, error)
      throw error
    }
    ErrorLogger(globalMessages.Error_executing_reset_password_API, 'error is null or undefined')
    throw new Error(globalMessages.Unable_to_process_your_request)
  }
}

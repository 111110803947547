import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Typography, TextField, Box } from '@mui/material'
// import { TextField } from '../../FormsUI'
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { ButtonPrimary, OtpField } from '../../FormsUI'
import './MultiFactorAuthentication.css'
import { useStylesMFA } from './Style'
import { toast } from 'react-toastify'
import { VerifyLoginPassCode, SendLoginPassCode } from './../../Controllers/MFAController'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import Messages from '../../../assets/data/globalMessages.json'
import { useFingerprint } from '../../../hooks/useFingerprint'
import VerifyAccountLogo from '../../../assets/icon/confirmation 3.png'
import { updateMFAStatusAsCompleted } from '../../Controllers/CommonController'
import { SetNewPassword } from '../../Controllers/LoginForgotPasswordController'

const MultiFactorAuthenticationOTP = () => {
  const otpLocation = useLocation()
  const navigate = useNavigate()
  const classes = useStylesMFA()
  const loginToken = JSON.parse(Cookies.get('token') ? Cookies.get('token') : '{ }')
  const [currentCount, setCount] = useState(10)
  const customerPhoneNumber = otpLocation?.state?.phoneNumber
  const customerEmail = Cookies.get('email')
  const { visitorId } = useFingerprint()
  const [disabledButton, setDisabledButton] = useState(false)
  const [otpValue, setOtpValue] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
  const isSecurityQuestionSaved = otpLocation?.state?.mfaQueries?.mfaDetails?.securityQuestionsSaved ?? false

  useEffect(() => {
    let otpSkipCookie = Cookies.get('otpSkip')
    if (!otpLocation?.state?.mfaQueries) {
      navigate('/customers/accountOverview')
    } else if (otpSkipCookie) {
      navigate('/MFA_verify')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = () => setCount(currentCount - 1)
    if (currentCount <= 0) {
      return
    }
    const id = setInterval(timer, 1000)
    return () => clearInterval(id)
  }, [currentCount])
  const checkEnteredOTP = (OTPObject) => {
    for (let key in OTPObject) {
      if (OTPObject[key] === '') return true
    }
    return false
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    setOtpValue((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const focusInputBox = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      const next = event.target.tabIndex - 1
      if (next > 0) {
        event.target.form.elements['otpNumber' + next].focus()
      }
    } else if (event.key !== 'Tab') {
      const next = event.target.tabIndex
      if (next < 6 && event.target.value) {
        event.target.form.elements['otpNumber' + (next + 1)].focus()
      }
    }
  }

  const getPasscode = (otp) => {
    let passCode = ''
    for (let key in otp) {
      passCode += otp[key]
    }
    return passCode
  }

  const handleClickSubmit = async () => {
    setDisabledButton(true)
    let enteredOTP = getPasscode(otpValue)
    let redirectURL = Cookies.get('afterMFARedirect')
    let response = await VerifyLoginPassCode(enteredOTP, customerEmail, visitorId, customerPhoneNumber, redirectURL)
    if (response?.data?.statusCode === 200) {
      navigate('/recovery-success')
    } else {
      if (
        response.data?.Message?.includes('account has been locked') ||
        response.data?.errorMessage?.includes('account has been locked')
      ) {
        toast.error(Messages.Contact_Support)
        navigate('/login')
      } else {
        setOtpValue({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
        toast.error(response.data?.Message ?? response.data?.errorMessage)
      }
    }
    setDisabledButton(false)
  }

  const resendOTP = async () => {
    setOtpValue({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
    let response = await SendLoginPassCode(customerPhoneNumber)
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log('resent response', response) //Left this console log intentionally for QA
    }
    if (response?.data?.passcodeInTextMessage) {
      toast.success('Successfully sent passcode')
      setCount(60)
    } else {
      toast.error(response.data?.Message)
    }
  }

  const getOTPTextField = (id, name, tabIndex) => {
    const opt = Object.keys(otpValue)
    return (
      <Grid item id={`wrap${id}`} style={{ margin: '0 5px', position: 'relative' }}>
        <OtpField
          id={id}
          name={name}
          value={otpValue[opt[tabIndex - 1]]}
          className={classes.otpNumber}
          lable="OTP"
          type="number"
          design="new"
          variant="outlined"
          onChange={(event) => handleChange(event)}
          inputProps={{ tabIndex: tabIndex, maxLength: 1 }}
          onKeyUp={(event) => focusInputBox(event)}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
          }}
        />
      </Grid>
    )
  }
  return (
    <div>
      <>
        <div data-testid="passcode-verification-container" className={classes.forgotPasswordRoot}>
          <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
            <Paper className={classes.forgotPasswordPaper}>
              <Grid item xs={12} md={7} gap={2} className={classes.mainContentArea}>
                <Link className={classes.arrowBack} to="/MFA_verify">
                  <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
                </Link>

                <Box display="flex" flexDirection="column" gap={2} paddingBottom={3} alignItems="center">
                  <Grid container className={classes.rotatingIcon}>
                    <img src={VerifyAccountLogo} alt="passwordlogo" className="spinAnimation" />
                  </Grid>

                  <Typography className={classes.title}>Verify Account</Typography>

                  <Typography className={classes.directiveText}>
                    Enter the code sent to your number: <span>{`(***) ***-${customerPhoneNumber.substr(-4)}.`} </span>
                  </Typography>
                  <Typography style={{ color: '#585858', marginTop: '-8px' }}>Code is valid for 15 minutes.</Typography>
                </Box>

                <form>
                  <Grid
                    justifyContent={'center'}
                    flexWrap={'unset'}
                    className={classes.otpContainer}
                    container
                    item
                    id="otpFieldWrapper"
                  >
                    {getOTPTextField('otpNumber1', 'otp1', 1)}
                    {getOTPTextField('otpNumber2', 'otp2', 2)}
                    {getOTPTextField('otpNumber3', 'otp3', 3)}
                    {getOTPTextField('otpNumber4', 'otp4', 4)}
                    {getOTPTextField('otpNumber5', 'otp5', 5)}
                    {getOTPTextField('otpNumber6', 'otp6', 6)}
                  </Grid>
                  <div className={classes.formContainer}>
                    <Typography className={classes.footerText}>
                      If you do not receive the code within 2 minutes,{' '}
                      {currentCount > 0 ? (
                        <span className="blueColorLink" style={{ cursor: 'pointer' }}>
                          resend code.
                        </span>
                      ) : (
                        <span onClick={resendOTP} style={{ cursor: 'pointer' }} className="blueColorLink">
                          resend code.
                        </span>
                      )}
                    </Typography>
                    <Grid item className={classes.footer}>
                      <ButtonPrimary
                        stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                        disabled={checkEnteredOTP(otpValue) || disabledButton}
                        onClick={handleClickSubmit}
                      >
                        Confirm
                      </ButtonPrimary>
                    </Grid>
                  </div>
                </form>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </>
    </div>
  )
}

export default MultiFactorAuthenticationOTP

import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import ErrorLogger from '../lib/ErrorLogger'
import getClientIp from './CommonController'

/***** To Send the passcode without Authentication*****/
export async function GenerateAndSendPasswordResetLink(email, visitorId) {
  try {
    let ipAddress = await getClientIp()
    //API
    let url = 'generate_send_password_reset_link'
    let param = ''
    let data = {
      email: email,
      deviceType: visitorId,
      ip: ipAddress,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Generate_And_Send_Password_Reset_Link_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

export async function VerifyUserPasswordResetLink(email, verificationLink, visitorId) {
  try {
    let ipAddress = await getClientIp()
    //API
    let url = 'verify_user_password_reset_link'
    let param = ''
    let data = {
      email: email,
      verificationLink: verificationLink,
      deviceType: visitorId,
      ip: ipAddress,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Verify_User_Password_Reset_Link_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

export async function SetNewPassword(password, user) {
  try {
    //API
    let url = 'set_new_password'
    let param = ''
    let data = {
      new_password: password,
      user: user,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Set_New_Password_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

export async function ValidateResetPasswordActivationToken(activationToken, visitorId) {
  try {
    //API
    let url = 'verify_user_password_reset_link'
    let ipAddress = await getClientIp()
    let param = ''
    let data = {
      unique_hash_id: activationToken,
      deviceType: visitorId,
      ip: ipAddress,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Set_New_Password_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

export async function VerifyUserPassword(email, password) {
  try {
    //API
    let url = 'verify_user_password'
    let param = ''
    let data = {
      email: email,
      password: password,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Set_New_Password_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

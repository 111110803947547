import { makeStyles } from '@mui/styles'
import overlayImage from '../../../assets/images/mariner_overlay_Logo.png'

const useStylesLogin = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  termsText: {
    fontSize: '0.938rem',
  },
  linkDesign: {
    color: '#0F4EB3',
    cursor: 'pointer',
    fontSize: '0.938rem',
  },
  paper: {
    padding: '30px 22%',
    margin: '70px 0px',
    borderRadius: '6px !important',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `rgba(255, 255, 255, .8)`,
    color: theme.palette.text.secondary,
    position: 'relative',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
    '@media (max-width: 670px)': {
      padding: '30px 10%',
    },
  },
  heading: {
    color: 'white',
    justify: 'center',
  },
  checkbox: {
    textAlign: 'initial',
    fontFamily: "'Muli', sans-serif !important",

    '@media (max-width: 600px)': {
      order: 2, // On smaller screens, the checkbox will visually appear after the "Forgot password?" link
    },
    forgotPassword: {
      '@media (max-width: 600px)': {
        order: 1, // On smaller screens, the "Forgot password?" link will visually appear before the checkbox
      },
    },
  },
  title: {
    fontSize: '25px',
    textAlign: 'center',
    color: '#171717',
    fontWeight: '400',
  },
  register: {
    fontSize: '0.844rem',
    textDecoration: 'none',
    color: '#0F4EB3',
    fontFamily: "'Muli', sans-serif !important",
    marginBottom: '0px',
  },
  mainGrid: {
    boxShadow: `0 16px 24px 2px rgb(0 0 0 / 14%),
    0 6px 30px 5px rgb(0 0 0 / 12%),
    0 8px 10px -7px rgb(0 0 0 / 20%)`,
    background: '#f5f2f2',
  },
  mainContentGrid: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  loginButton: {
    textAlign: 'center',
    margin: '5% 0 5% 0',
  },
  emailGrid: {
    lineHeight: '2',
    margin: '0px 0px 30px 0px',
  },
  passwordGrid: {
    margin: '0px 0px 30px 0px',
  },
  registerGrid: {
    textAlign: 'center',
    width: '100%',
    margin: '2% 0px 0px 0px',
  },
  deleteAcctGrid: {
    textAlign: 'center',
    width: '100%',
    margin: '5% 0px',
  },
  separator: {
    border: 'none',
    height: '1px',
    backgroundColor: '#C9C9C9',
    width: '80%',
    margin: '5% auto',
    textAlign: 'center',
  },
  loginHelpDialogHeading: {
    fontSize: '25px',
    textAlign: 'center',
    color: '#171717',
    fontWeight: '400',
    paddingTop: '15px',
  },
  createPasswordPaper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `rgba(255, 255, 255, .8)`,
    color: theme.palette.text.secondary,
    boxShadow: `0 16px 24px 2px rgb(0 0 0 / 14%),
0 6px 30px 5px rgb(0 0 0 / 12%),
0 8px 10px -7px rgb(0 0 0 / 20%)`,
  },
  resetButton: {
    textAlign: 'center',
  },
  emailInputGrid: {
    lineHeight: '2',
    padding: '0px 0px 16px 0px',
  },
  logInGrid: {
    paddingTop: '30px',
    margin: '0',
  },
  passwordWrap: {
    width: '100%',
    padding: '0px 0px 16px 0px',
    marginBottom: 10,
  },
  loginRecaptcha: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
  },
  forgotEmailGrid: {
    textAlign: 'right',
    width: '100%',
  },
  passwordLogo: {
    justifyContent: 'center',
    padding: '20px',
  },

  deleteAcctLogo: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  responsiveImage: {
    width: '20%', // Default width

    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    height: 'auto',
  },
}))

export { useStylesLogin }

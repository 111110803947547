import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './Login.css'

export default function validateUserEnteredInput(userInput, section) {
  //this page used to show the password validation criteria
  //We have two designs in our application right now, design one has 2 sections and new design has 1 section
  //depends on the section value we render the design
  //design 1 => sections: oldDesign0, oldDesign1
  //design 2 => sections: newDesign

  if (section === 'oldDesign1') {
    return (
      <>
        <li className={userInput.length >= 10 && userInput.length <= 64 ? 'validation-success' : 'validation-failed'}>
          Between 10 and 64 characters in length
        </li>

        <li className={/[A-Z]/.test(userInput) ? 'validation-success' : 'validation-failed'}>
          At least 1 uppercase letter
        </li>

        <li className={/[a-z]/.test(userInput) ? 'validation-success' : 'validation-failed'}>
          At least 1 lowercase letter
        </li>
      </>
    )
  } else if (section === 'newDesign') {
    return (
      <>
        <li style={{ display: 'flex', alignContent: 'center' }}>
          {userInput.length >= 10 && userInput.length <= 64 ? (
            <CheckCircleIcon style={{ color: '#00D288', transform: 'scale(0.8)' }} />
          ) : (
            <InfoOutlinedIcon style={{ paddingRight: 5, transform: 'scale(0.8)' }} />
          )}{' '}
          Must be between 10 and 64 characters in length
        </li>
        <li style={{ display: 'flex', alignContent: 'center' }}>
          {/[A-Z]/.test(userInput) ? (
            <CheckCircleIcon style={{ color: '#00D288', transform: 'scale(0.8)' }} />
          ) : (
            <InfoOutlinedIcon style={{ paddingRight: 5, transform: 'scale(0.8)' }} />
          )}
          At least 1 uppercase letter
        </li>
        <li style={{ display: 'flex', alignContent: 'center' }}>
          {/[a-z]/.test(userInput) ? (
            <CheckCircleIcon style={{ color: '#00D288', transform: 'scale(0.8)' }} />
          ) : (
            <InfoOutlinedIcon style={{ paddingRight: 5, transform: 'scale(0.8)' }} />
          )}
          At least 1 lowercase letter
        </li>
        <li style={{ display: 'flex', alignContent: 'center' }}>
          {/\d/.test(userInput) ? (
            <CheckCircleIcon style={{ color: '#00D288', transform: 'scale(0.8)' }} />
          ) : (
            <InfoOutlinedIcon style={{ paddingRight: 5, transform: 'scale(0.8)' }} />
          )}
          At least 1 number
        </li>
        <li style={{ display: 'flex', alignContent: 'center' }}>
          {/[@$!%*#?&~^]+/.test(userInput) && !/[\-_=+`,<.>\\|\/(\)\[\]{};:'"]+/.test(userInput) ? (
            <CheckCircleIcon style={{ color: '#00D288', transform: 'scale(0.8)' }} />
          ) : (
            <InfoOutlinedIcon style={{ paddingRight: 5, transform: 'scale(0.8)' }} />
          )}
          At least 1 special character @$!%*#?&~^
        </li>
      </>
    )
  } else {
    return (
      <>
        <li className={/\d/.test(userInput) ? 'validation-success' : 'validation-failed'}>At least 1 number</li>

        <li
          className={
            /[@$!%*#?&~^]+/.test(userInput) && !/[\-_=+`,<.>\\|\/(\)\[\]{};:'"]+/.test(userInput)
              ? 'validation-success'
              : 'validation-failed'
          }
        >
          At least 1 special character @$!%*#?&~^
        </li>
      </>
    )
  }
}

import SearchIcon from '@mui/icons-material/Search'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import PlacesAutocomplete from 'react-places-autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  howManyBranchesforBranchLocatorPages,
  VirtualBranch as virtualBranchList,
} from '../../../assets/data/marinerBusinesStates'
import BranchImageMobile from '../../../assets/images/Branch_Locator_Mobile.webp'
import BranchImageWeb from '../../../assets/images/Branch_Locator_Web_Image.webp'
import TitleImage from '../../../assets/images/Favicon.png'
import BranchLocatorController from '../../Controllers/BranchLocatorController'
import LoadGMaps from '../../Controllers/LoadGMaps'
import { ButtonPrimary } from '../../FormsUI'
import ErrorLogger from '../../lib/ErrorLogger'
import CustomerRatings from '../MyBranch/CustomerRatings'
import './BranchLocator.css'
import { useStylesMyBranch } from './Style'
import Cookies from 'js-cookie'
import globalMessages from '../../../assets/data/globalMessages.json'
import ScrollToTop from '../ScrollToTop'

function VirtualBranch() {
  const formatString = (str) => {
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const [mapLoading, setMapLoading] = useState(() => false)
  const [address, setAddress] = useState(() => '')
  const [loading, setLoading] = useState(() => false)
  const [stateName, setStateName] = useState('')
  let stateNamefromUrl = location?.pathname?.split('/')
  const [name, setName] = useState(location?.state?.value ?? formatString(stateNamefromUrl[2] ?? ''))
  const refSearch = useRef()
  const classes = useStylesMyBranch()
  const navigate = useNavigate()

  useEffect(() => {
    setName(stateName !== '' ? stateName : name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateName])

  const getBranchLists = async () => {
    try {
      let searchText = refSearch?.current?.value.trim().length ? refSearch?.current?.value.trim() : ''
      setLoading(true)
      let result = await BranchLocatorController(searchText, howManyBranchesforBranchLocatorPages.StatePage, true)
      if (result.status == 400 || virtualBranchList.includes(result?.data?.branchData[0]?.BranchNumber)) {
        if (!toast.isActive('closeToast')) {
          toast.error(globalMessages.No_Branch_in_Area, { toastId: 'closeToast' })
        }
        setLoading(false)
      } else if (result?.data?.branchData.length === 0) {
        setLoading(false)
        let branchName = result?.data?.stateLongName
        setName(branchName)
        setStateName(branchName)
      } else {
        navigate(`/branch-locator/${result.data.stateLongName.replace(/\s+/g, '-').toLowerCase()}`)
        setName(result?.data?.stateLongName)
        setStateName(result?.data?.stateLongName)
        return result?.data?.branchData
      }
    } catch (error) {
      ErrorLogger(' Error from branchList ', error)
    }
  }

  useEffect(() => {
    LoadGMaps(() => {
      setMapLoading(true)
    })
  }, [])
  const handleSelect = async (value) => setAddress(value)

  const loginToken = JSON.parse(Cookies.get('token') ? Cookies.get('token') : '{ }')
  const searchAndgetList = (
    <Grid id="findBranchWrapTwo" className={classes.blueBackground} data-testid="searchBranchBox">
      <h4 className={classes.headigText}>
        Personal Loans in <strong>{name}</strong>
      </h4>
      <Grid id="findBranchGrid">
        <SearchIcon id="searchIcon" className="searchIcon" />
        <PlacesAutocomplete id="addressOne" value={address} onChange={setAddress} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading2 }) => (
            <div className="searchInputWrap">
              <input
                data-testid="stateSearchInput"
                id="search1"
                ref={refSearch}
                className="stateSearch"
                {...getInputProps({ placeholder: 'Enter city & state or zip code' })}
              />
              <div className="serachResult">
                {loading2 && <div>Loading...</div>}
                {React.Children.toArray(
                  suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                    }
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        <span style={{ padding: '10px 0px' }}>{suggestion.description}</span>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <ButtonPrimary
          className="branchSearchButton"
          id="branchSearchButton"
          aria-label="branchSearchButton"
          data-testid="testBranchSearchButton"
          onClick={getBranchLists}
          stylebutton='{"background": "#FFBC23", "color": "black", "borderRadius": "50px", "padding":"0px 30px"}'
        >
          <ArrowForwardIcon id="goIcon" className="goIcon" />
        </ButtonPrimary>
      </Grid>
    </Grid>
  )

  const searchInputBox = (
    <Grid className="branchLayoutGrid" container>
      <Grid className="branchImage" item md={7} sm={12} xs={12}>
        {BranchImageMobile ? <img className="mobileImage" src={BranchImageMobile} alt="MF Banner" /> : ''}
        {BranchImageWeb ? (
          <img width="620px" height="590px" className="webImage" src={BranchImageWeb} alt="MF Banner" />
        ) : (
          ''
        )}
      </Grid>

      <Grid className="greyBackground mobilePadding" item md={5} sm={12} xs={12}>
        <Grid>
          <Grid className="blueBoxWrap">
            {mapLoading ? (
              searchAndgetList
            ) : (
              <Grid id="findBranchWrapTwo" className={classes.blueBackground} data-testid="searchBranchBox"></Grid>
            )}
            <p className="branchLocatorHeadingMain">Get one on one support for a personal loan near you</p>

            <Typography className="branchLocatorHeading">
              <span className="branchSmallText">
                <b className="numberText">Operating coast-to-coast</b>with physical locations in over half the states
              </span>
            </Typography>

            <Typography className="branchLocatorHeading">
              <b className="numberText">$1k - $25k</b>

              <span className="branchSmallText">Available loan amount †</span>
            </Typography>

            <Typography className="branchLocatorHeading">
              <b className="numberText">4.8</b>

              <span className="branchSmallText">Star Rating based on over 13,000 verified reviews ***</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const virtualBranchInfo = (
    <Grid container item md={6} sm={12}>
      <Grid item md={12} sm={12}>
        <Typography className="comingSoonText" variant="h6">
          {name} - Coming Soon!
        </Typography>
        <Typography className="vSmallText">Our virtual branch is open to service your needs. *</Typography>
      </Grid>

      <Grid className="openHoursTable" item md={12} sm={12}>
        <table>
          <tbody>
            <tr>
              <td>Mon</td>
              <td>8:30 AM - 7:00 PM</td>
            </tr>
            <tr>
              <td>Tue</td>
              <td>8:30 AM - 7:00 PM</td>
            </tr>
            <tr>
              <td>Wed</td>
              <td>8:30 AM - 7:00 PM</td>
            </tr>
            <tr>
              <td>Thu</td>
              <td>8:30 AM - 7:00 PM</td>
            </tr>
            <tr>
              <td>Fri</td>
              <td>8:30 AM - 6:00 PM</td>
            </tr>
          </tbody>
        </table>
        <Typography className="notesSmallText">
          *Please note that all listed time slots are in Eastern Time (ET), please adjust accordingly for your local
          time zone.
        </Typography>
      </Grid>
      <Grid container item md={12} className="contactInfo">
        <span>
          <PhoneOutlinedIcon />
          <a href="tel:+8447560630">844 - 756 - 0630</a>
        </span>
        <span>
          <MailOutlineRoundedIcon />
          <a href="mailto:virtualbranch@marinerfinance.com">virtualbranch@marinerfinance.com</a>
        </span>
      </Grid>
    </Grid>
  )
  const virtualBranchBriefing = (
    <Grid container item md={6}>
      <Grid>
        <Typography variant="h6" className="vRightheading">
          Personal Loans in {name}
        </Typography>
        <Typography className="vRightPara">
          Mariner Finance offers loans in {name}. Apply online today or call to contact one of our representatives.
        </Typography>
      </Grid>

      <Grid>
        <Typography variant="h6" className="vRightheading">
          We{"'"}re here for you
        </Typography>
        <Typography className="vRightPara">
          Whether you want to consolidate your debt, pay your bills, or travel to your dream destination, Mariner
          Finance is here to help you. Explore our personal loans today and move one step closer to addressing your
          financing needs.
        </Typography>
      </Grid>
      <Link
        href={!loginToken.isLoggedIn ? '/select-amount' : '/customers/resumeApplication'}
        className="nav_link branchHeaderLinksLast"
      >
        <ButtonPrimary stylebutton='{"color": "black"}'>APPLY NOW</ButtonPrimary>
      </Link>
    </Grid>
  )

  //View part
  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />{' '}
        </Grid>
      ) : (
        <div data-testid="branchpage_component">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" type="image/png" href={TitleImage} sizes="16x16" />
            <link rel="canonical" href={window.location.href} />
            <link rel="preload" as="image" href={BranchImageWeb} />
            {/* <meta
          name="description"
          content={`Looking for a personal loan in ${ branch_Details?.current?.BranchName }, ${ stateShortName ?? stateShortNm?.current }? Our ${ branch_Details?.current?.BranchName }, ${ stateLongNm?.current } branch welcomes you for personal loans that fit your needs.`}
        /> */}
          </Helmet>
          <Grid className="greyBackground" container justifyContent={'center'}>
            <ScrollToTop />
            <Grid className="addressCardWrap">
              {searchInputBox}
              <Grid className="vBranchwhiteBackgroundGrid">
                <h3 className="vTopHeading">Branches Near You</h3>
                <Grid container className="info_BriefingWrap">
                  {virtualBranchInfo}
                  {virtualBranchBriefing}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="customerRatingsWrap">
              <CustomerRatings />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default VirtualBranch

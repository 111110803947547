import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { ButtonPrimary } from '../../../FormsUI'
import globalMessages from '../../../../assets/data/globalMessages.json'
import { useStylesVerifyAccount } from './Style'
import PasswordLogo from '../../../../assets/icon/I-Password.png'

function AccountRecover() {
  const classes = useStylesVerifyAccount()
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={11} sm={10} md={8} lg={6} xl={6} justifyContent="center" alignItems="center" container>
        <Paper className={classes.paper}>
          <Grid container className={classes.passwordLogo}>
            <img src={PasswordLogo} alt="PasswordLogo" />
          </Grid>
          <Typography className={classes.title}>Account Recovered Successfully</Typography>
          <Grid sx={{ width: '80%', margin: 'auto' }}>
            <Typography sx={{ textAlignLast: 'center', color: 'black' }}>
              The below email address is the unique email associated with your Mariner Finance CAC account.
            </Typography>
          </Grid>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid className={classes.userLogo}>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#013474',
                  borderRadius: '50%',
                  border: '1px solid #febd10',
                }}
              ></div>
            </Grid>

            <Typography sx={{ fontWeight: 'bold' }}>user_testing123@gmail.com</Typography>
          </Grid>
          <Grid item xs={12} className={classes.sendLinkButton}>
            <ButtonPrimary
              type="submit"
              stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px"}'
            >
              Sign in
            </ButtonPrimary>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default AccountRecover

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatLeft {
  text-align: left;
}
.yellowTextNew {
  color: #ffbc23 !important;
  margin-left: 10px;
  font-size: xx-large;
  text-align: left;
}
.floatingButton {
  position: absolute;
}
.imageGrid {
  text-align: center;
}
.cardWrapperRec {
  padding-top: '4%';
  margin-top: '5%';
  margin-bottom: '2%';
}
.floatLeftRec {
  text-align: left;
}

.arrowBackRec {
  position: relative;
}
.floatingButtonRec {
  position: absolute;
}
.spinAnimationRec {
  animation-name: rotation;
  animation-duration: 3s;
}
.borrowCSSLPRec {
  font-size: 1.538rem !important;
  font-weight: 400 !important;
  line-height: 110% !important;
}
.accountRecoveryText {
  margin: 10px 0px !important;
}
.blockDivRec {
  padding: 0px 16px;
  margin: auto;
}
.subTitleText {
  margin: auto;
  padding-bottom: 26px;
}
.ssnText {
  padding-bottom: 26px;
}
#ssnTextFieldOne::before {
  content: 'Social Security Number*';
  position: absolute;
  top: -5px;
  min-width: 200px;
  padding-left: 10px;
  font-size: 0.898rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Login/ForgotEmail/AccountRecovery.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,wBAAwB;EACxB,sBAAsB;AACxB;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,4BAA4B;AAC9B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".floatLeft {\n  text-align: left;\n}\n.yellowTextNew {\n  color: #ffbc23 !important;\n  margin-left: 10px;\n  font-size: xx-large;\n  text-align: left;\n}\n.floatingButton {\n  position: absolute;\n}\n.imageGrid {\n  text-align: center;\n}\n.cardWrapperRec {\n  padding-top: '4%';\n  margin-top: '5%';\n  margin-bottom: '2%';\n}\n.floatLeftRec {\n  text-align: left;\n}\n\n.arrowBackRec {\n  position: relative;\n}\n.floatingButtonRec {\n  position: absolute;\n}\n.spinAnimationRec {\n  animation-name: rotation;\n  animation-duration: 3s;\n}\n.borrowCSSLPRec {\n  font-size: 1.538rem !important;\n  font-weight: 400 !important;\n  line-height: 110% !important;\n}\n.accountRecoveryText {\n  margin: 10px 0px !important;\n}\n.blockDivRec {\n  padding: 0px 16px;\n  margin: auto;\n}\n.subTitleText {\n  margin: auto;\n  padding-bottom: 26px;\n}\n.ssnText {\n  padding-bottom: 26px;\n}\n#ssnTextFieldOne::before {\n  content: 'Social Security Number*';\n  position: absolute;\n  top: -5px;\n  min-width: 200px;\n  padding-left: 10px;\n  font-size: 0.898rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

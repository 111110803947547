import React from 'react'
import ReactDOM from 'react-dom/client'
import { GlobalStateProvider } from '../src/contexts/GlobalStateProvider'
import './assets/styles/index.css'
import './assets/Fonts/fonts.css'
import App from './components/App'
import { BrowserRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <GlobalStateProvider>
    {' '}
    <BrowserRouter>
      <App />{' '}
    </BrowserRouter>
  </GlobalStateProvider>
)

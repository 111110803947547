/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Paper,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ButtonPrimary, Popup } from '../../FormsUI'
import { useStylesMFA } from './Style'
import PropTypes from 'prop-types'
import PhoneNumberPopUp from './PhoneNumberPopUp'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import { useMutation } from 'react-query'
import { SendLoginPassCode } from '../../Controllers/MFAController'
import Cookies from 'js-cookie'
import confirmation3 from '../../../assets/icon/confirmation3.png'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import './mfa.css'
const Buffer = require('buffer/').Buffer

const MFASelection = ({
  securityQuestionsSaved,
  phoneNumberList = [],
  mfaDetails,
  disableSecurityQuestions,
  newResetPassword,
}) => {
  const classes = useStylesMFA()
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [selection, setSelection] = useState()
  const { mutateAsync, isLoading } = useMutation(SendLoginPassCode)
  const SECURITY_QUESTIONS = 'security questions'
  const PHONE = 'phone'
  const DISABLE_CONDITION = value === PHONE && selection !== SECURITY_QUESTIONS && Boolean(selection)
  let pNumber = ''
  // const buttonDisable = () => {
  //   if (!value && !selection) {
  //     return true
  //   } else if ((value === SECURITY_QUESTIONS && selection === SECURITY_QUESTIONS) || DISABLE_CONDITION) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  const buttonDisable = () => {
    return !value || isLoading
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handlePopUp = () => {
    setPopUp(true)
  }
  const processSelection = async () => {
    Cookies.set('getPhoneFromUser', phoneNumberList?.length > 0 ? false : true)

    if (value === PHONE) {
      let encodedPhoneList = mfaDetails.mfaDetails.encoded_PhoneNumbers
      let phoneNumber = ''

      encodedPhoneList.forEach((number, i) => {
        let last4 = ''
        if (pNumber) {
          last4 = pNumber.substring(6)
        } else if (selection) {
          last4 = selection.substring(6)
        }
        let bufferObj = Buffer.from(number, 'base64')
        let decodedString = bufferObj.toString('utf8')

        if (last4 === decodedString.substring(6)) {
          phoneNumber = decodedString
        }
      })

      if (phoneNumber === '') {
        toast.error('You must select a phone number.')
      } else {
        // Handle phone option when there's only one phone number or phone is selected from popup
        const passCodeResponse = await mutateAsync(phoneNumber)
        if (process.env.NODE_ENV !== 'production') {
          console.log(passCodeResponse) // Left this console log intentionally for QA
        }
        passCodeResponse?.data?.passcodeInTextMessage
          ? navigate('/MFA-OTP_verify', {
              state: {
                phoneNumber: phoneNumber,
                mfaQueries: mfaDetails,
                currentFlow: true,
                newResetPassword: newResetPassword,
              },
            })
          : toast.error(passCodeResponse.data?.Message) // Navigate to OTP page or else show error.
      }
    } else if (value === SECURITY_QUESTIONS && securityQuestionsSaved) {
      // Handle security questions option
      navigate('/MFA-SecurityQuestions_verify', {
        state: { mfaSecurityQuestions: mfaDetails, currentFlow: true, newResetPassword: newResetPassword },
      })
    } else if (value === SECURITY_QUESTIONS && !securityQuestionsSaved) {
      navigate('/mfa-kbaQuestions_verify', {
        state: { mfaSecurityQuestions: mfaDetails, currentFlow: true, newResetPassword: newResetPassword },
      })
    }
  }

  const handleClick = async () => {
    if (value === PHONE && phoneNumberList?.length > 1) {
      setPopUp(true)
    } else {
      if (value === PHONE && phoneNumberList?.length === 1) {
        pNumber = phoneNumberList[0].number
      }
      processSelection()
    }
  }

  const handlePopupClick = async () => {
    setPopUp(false)
    processSelection()
  }

  const handlePopUpClose = () => {
    setPopUp(false)
  }

  const handlePhoneNumberChange = (event) => {
    setSelection(event.target.value)
  }

  const securityCode = (
    <div className={classes.securityCodeText}>
      Receive an authentication PIN on your phone number
      <br />
      {/* {phoneNumberList?.length === 1 ? (
        <span>{`Get a code on (***) ***-${phoneNumberList[0]?.number.substr(-4)}`}</span>
      ) : DISABLE_CONDITION ? (
        <span>To phone number : {`(***) ***-${selection?.substr(-4)}`}</span>
      ) : (
        <span>Get a code on your preferred phone number</span>
      )} */}
    </div>
  )
  const securityQuestions = (
    <div className={classes.securityQuestions}>
      Security Questions <br />
      {/* Answer Identity Verification Questions.{' '} */}
    </div>
  )

  return (
    <div className={isLoading ? classes.loadingOn : classes.loadingOff}>
      <ScrollToTop />
      <Grid data-testid="mfa_Selection">
        <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
          <Paper className={classes.forgotPasswordPaper}>
            <Grid item xs={12} md={10} className={classes.mainContentArea}>
              <Link className={classes.arrowBack} to="/login">
                <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
              </Link>

              <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                <Grid container className={classes.rotatingIcon}>
                  <img src={confirmation3} alt="verify Identity" className="spinAnimation" />
                </Grid>
                <Typography className={classes.title}>Verify your Identity</Typography>

                <Typography className={classes.directiveText} data-testid="title1">
                  {phoneNumberList?.length
                    ? `For your security, please select a method to verify your identity`
                    : `For your security, please select security question verification to verify your identity`}
                  {/* Phone Number Array Length is 0, then only security question */}
                </Typography>
              </Box>
              <Grid container direction="column" display="flex" alignItems="center">
                <Box>
                  <RadioGroup
                    id="textAndCallss"
                    aria-label="method"
                    name="method"
                    value={value}
                    onChange={handleChange}
                  >
                    {phoneNumberList?.length > 0 && (
                      <FormControlLabel
                        id="FormControlLabelNew"
                        className={classes.radioGroup}
                        value={PHONE}
                        control={
                          <Radio
                            className={classes.customRadio}
                            data-testid="phoneSelection"
                            // onClick={() => handlePopUp()}
                          />
                        }
                        label={securityCode}
                      />
                    )}
                    <FormControlLabel
                      id="FormControlLabelNew"
                      className={classes.radioGroup}
                      value={SECURITY_QUESTIONS}
                      control={
                        <Radio
                          className={classes.customRadio}
                          disabled={disableSecurityQuestions}
                          data-testid="questionSelection"
                          onClick={() => setSelection(SECURITY_QUESTIONS)}
                        />
                      }
                      label={securityQuestions}
                    />
                  </RadioGroup>
                </Box>
              </Grid>
              <Box className={classes.disclosure}>
                <ButtonPrimary
                  data-testid="next_Step"
                  stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                  disabled={buttonDisable()}
                  onClick={handleClick}
                >
                  Continue
                  <AutorenewIcon
                    className="rotatingIcon"
                    style={{
                      display: isLoading ? 'block' : 'none',
                    }}
                  />
                </ButtonPrimary>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <div className={isLoading ? classes.loadingOn : classes.loadingOff}>
        {/* Conditional phone number selection dialog */}
        {popUp && (
          <Dialog
            className={classes.dialogRoot}
            open={popUp}
            onClose={handlePopUpClose}
            maxWidth="sm"
            PaperProps={{ classes: { root: classes.dialogPaper } }}
          >
            <Box className={classes.iconContainer}>
              <IconButton aria-label="close" onClick={handlePopUpClose} edge="end">
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid item className={classes.dialogContainer}>
              <DialogTitle className={classes.subTitle}>
                Select from one of your preferred phone number(s) to receive a one time passcode.
              </DialogTitle>
              <DialogContent>
                <RadioGroup aria-label="phone" name="phone" value={selection} onChange={handlePhoneNumberChange}>
                  {phoneNumberList.map((phoneNumber, index) => (
                    <FormControlLabel
                      className={classes.radioGroup}
                      key={index}
                      value={phoneNumber.number}
                      control={<Radio className={classes.customRadio} />}
                      label={`Get code on (***) ***-${phoneNumber.number.slice(-4)}`}
                    />
                  ))}
                </RadioGroup>
              </DialogContent>
              <DialogActions className={classes.centeredContent}>
                <ButtonPrimary
                  onClick={handlePopupClick}
                  disabled={buttonDisable()}
                  stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                >
                  Send Code
                </ButtonPrimary>
              </DialogActions>
            </Grid>
          </Dialog>
        )}
      </div>
    </div>
  )
}

MFASelection.propTypes = {
  securityQuestionsSaved: PropTypes.bool,
  phoneNumberList: PropTypes.array,
  mfaDetails: PropTypes.object,
  disableSecurityQuestions: PropTypes.bool,
}

export default MFASelection

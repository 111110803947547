import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import * as yup from 'yup'
import globalValidation from '../../../assets/data/globalMessages.json'
import globalMessages from '../../../assets/data/globalMessages.json'
import ForgotPasswordMessages from '../../../assets/data/ForgetPasswordMessages.json'
import { ButtonPrimary, PasswordField } from '../../FormsUI'
import ScrollToTop from '../ScrollToTop'
import { useStylesForgotPassword } from './Style'
import validateUserEnteredInput from '../Login/ValidateUserEnteredInputPasswordReset'
import PasswordLogo from '../../../assets/icon/confirmation 2.png'
import { useSession } from '../../../contexts/SessionContext'
import { useFingerprint } from '../../../hooks/useFingerprint'

// new controller
import { resetPassword } from '../../Controllers/PasswordRecoveryController'

//Yup validations for all the input fields
//const email = Cookies.get('email')
const ResetPasswordvalidationSchema = yup.object().shape({
  password: yup
    .string(globalValidation.PasswordEnter)
    .max(64, globalValidation.PasswordMax)
    .min(10, globalValidation.PasswordMin)
    .matches(
      /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&~^])[a-zA-Z0-9@$!%*#?&~^]{10,64})$/,
      globalValidation.PasswordCriteria
    )
    .required(globalValidation.PasswordNewRequired),
  confirmPassword: yup
    .string(globalValidation.PasswordEnter)
    .oneOf([yup.ref('password'), null], globalValidation.PasswordConfirmationMatch)
    .max(64, globalValidation.PasswordMax)
    .min(10, globalValidation.PasswordMin)
    .matches(
      /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&~^])[a-zA-Z0-9@$!%*#?&~^]{10,64})$/,
      globalValidation.PasswordCriteria
    )
    .required(globalValidation.PasswordConfirmationRequired),
})
//Begin: Login page
export default function ResetForgotPassword(props) {
  const classes = useStylesForgotPassword()
  const navigate = useNavigate()
  const { visitorId } = useFingerprint()
  const useQueryURL = () => new URLSearchParams(useLocation().search)
  const queryString = useQueryURL()
  const activationToken = queryString.get('activation_token')
  const { state: session, dispatch } = useSession() // Use session state and dispatch from context

  // Local scoped state
  const [isLoading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema: ResetPasswordvalidationSchema,
    // On login submit
    onSubmit: async (values) => {
      try {
        let response = await resetPassword(values.password, session?.mfaOptions?.sessionID)
        if (response) {
          toast.success(ForgotPasswordMessages.Password_Reset_Successful)

          Cookies.set('isPasswordReset', '1')

          setTimeout(() => {
            navigate('/login')
          }, 5000) // delays for 5 seconds, you can adjust the time as needed
        }
      } catch (error) {
        toast.error(error?.message || globalMessages.Unable_to_process_your_request)
      } finally {
        setLoading(false)
      }
    },
  })
  //Preventing space key
  const preventSpace = (event) => {
    if (event.keyCode === 'Space') {
      event.preventDefault()
    }
  }

  //View Part
  return (
    <div className={classes.forgotPasswordRoot} data-testid="ResetPassword_component">
      <ScrollToTop />
      <Grid container className={classes.forgotPasswordContainer}>
        <Grid item xs={11} md={8}>
          {isLoading ? (
            <Grid align="center" data-testid="loader-circular-icon" className={classes.centeredContent}>
              <CircularProgress />
            </Grid>
          ) : (
            <Paper className={classes.forgotPasswordPaper}>
              <Grid item xs={11} md={8} className={classes.mainContentArea} style={{ alignItems: 'center' }}>
                <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                  <Grid container className={classes.rotatingIcon}>
                    <img src={PasswordLogo} alt="PasswordLogo" />
                  </Grid>
                  <Typography className={classes.title} data-testid="title" color="textSecondary">
                    Reset your password
                  </Typography>
                  <Typography className={classes.directiveText}>
                    Please choose a new password to login to your account
                  </Typography>
                </Box>

                <form onSubmit={formik.handleSubmit}>
                  <Grid>
                    <PasswordField
                      className={classes.customLabelField}
                      name="password"
                      label="New Password*"
                      id="password"
                      type="password"
                      design="new"
                      onKeyDown={preventSpace}
                      materialProps={{ maxLength: '64' }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      labelStyle={classes.inputLabel}
                    />
                  </Grid>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Grid container>
                      <ul className={classes.errorValidationColumn}>
                        {validateUserEnteredInput(formik.values.password, 'newDesign', true)}
                      </ul>
                    </Grid>

                    <Grid>
                      <PasswordField
                        className={classes.customLabelField}
                        id="retypeNewPasswordWrap"
                        name="confirmPassword"
                        type="password"
                        design="new"
                        label="Confirm New Password*"
                        onKeyDown={preventSpace}
                        autoComplete="new-password"
                        materialProps={{ maxLength: '64', autoComplete: 'off' }}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        labelStyle={classes.inputLabel}
                      />
                    </Grid>
                  </Box>
                  <Box className={classes.disclosure}>
                    <Typography style={{ color: '#585858', margin: '0 0 1rem 0' }}>
                      Password cannot be a previously used password
                    </Typography>
                  </Box>
                  <Grid className={classes.footer}>
                    <ButtonPrimary
                      type="submit"
                      data-testid="submitButton"
                      stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px"}'
                      disabled={!formik.isValid || !formik.dirty || isLoading}
                    >
                      Reset Password
                    </ButtonPrimary>
                    <Typography className={classes.footerText}>
                      Know your password?{' '}
                      <Link to="/login" className={classes.footerLink}>
                        Sign in
                      </Link>
                    </Typography>
                  </Grid>
                </form>
              </Grid>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 599px) {
  #closeBtn {
    right: 5px !important;
    top: 0px !important;
  }

  #consumerDialogHeading {
    font-size: 26px;
    font-weight: bold;
  }
}

#buttonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

#consumerMain {
  position: relative;
}

#closeBtn {
  position: absolute;
  right: 14px;
  top: 5px;
}

#stayBtn {
  margin-right: 10px;
  font-family: 'Muli', sans-serif !important;
  font-weight: 700;
  padding: 0px 35px;
  box-shadow: none;
  text-transform: none !important;
}

#Continue {
  font-family: 'Muli', sans-serif !important;
  font-weight: 700;
  padding: 0px 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/ConsumerFooterDialog/Style.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@media only screen and (max-width: 599px) {\n  #closeBtn {\n    right: 5px !important;\n    top: 0px !important;\n  }\n\n  #consumerDialogHeading {\n    font-size: 26px;\n    font-weight: bold;\n  }\n}\n\n#buttonWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n#consumerMain {\n  position: relative;\n}\n\n#closeBtn {\n  position: absolute;\n  right: 14px;\n  top: 5px;\n}\n\n#stayBtn {\n  margin-right: 10px;\n  font-family: 'Muli', sans-serif !important;\n  font-weight: 700;\n  padding: 0px 35px;\n  box-shadow: none;\n  text-transform: none !important;\n}\n\n#Continue {\n  font-family: 'Muli', sans-serif !important;\n  font-weight: 700;\n  padding: 0px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { makeStyles } from '@mui/styles'

const dynamicStyles = makeStyles((theme) => ({
  dynamic: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid', // Set your desired color here
        borderColor: '#013474',
        '&:hover': {
          borderColor: 'blue !important', // Color on hover
        },
      },
      '&:hover': {
        borderColor: 'blue !important', // Color on hover
      },
    },
  },
}))
export default dynamicStyles

export const footerMenuList = [
  {
    link: 'termsofuse',
    testID: 'termsofuse',
    menuText: 'Terms of Use',
  },
  {
    link: 'resources/legal/licensing-disclosures/',
    testID: 'licenseDisclosure',
    menuText: 'Licensing & Disclosures',
  },
  {
    link: 'textingTermsOfUse',
    testID: 'textingTermsOfUse',
    menuText: 'Texting Terms of Use',
  },
  {
    link: 'websiteAccessibility',
    testID: 'websiteAccessibility',
    menuText: 'Website Accessibility Statement',
  },
  {
    link: 'californiaPersonalInformation',
    testID: 'californiaPersonalInformation',
    menuText: 'California Personal Information Collection Notice',
  },
  {
    link: 'cac-termsofuse',
    testID: 'cac-termsofuse',
    menuText: 'CAC Terms of Use',
  },
]

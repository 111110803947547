import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
// import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import fbIcon from '../../../assets/icon/fb.png'
import linkedInIcon from '../../../assets/icon/in.png'
import InstagramIcon from '../../../assets/icon/insta.png'
import TwitterIcon from '../../../assets/icon/twitter.png'
import housingImage from '../../../assets/images/Equalhousinglender2.png'
import whiteLogo from '../../../assets/images/logo-yellow-white.png'
import badge from '../../../assets/images/Platinum-Trusted-Service-New.jpg'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import ConsumerDialog from '../ConsumerFooterDialog/ConsumerDialog'
import SocialMediaDialog from '../ConsumerFooterDialog/SocialMediaDialog'
import { footerMenuList } from '../../../assets/data/footerMenu'
import './NormalFooter.css'

export default function NormalFooterNew() {
  const [consumer, setConsumer] = useState(false)
  const [socialMedia, setSocialMedia] = useState(false)
  const [URL, setURL] = useState('')
  //Consumer popup
  const handleOpenConsumer = () => {
    setConsumer(true)
  }
  // Social media redirect popup
  const handleOpensocialMedia = (customUrl) => {
    setURL(customUrl)
    setSocialMedia(true)
  }

  const californiaFooterMenu = (deviceType) => {
    return (
      <>
        <div className="footer-content">
          <a
            data-testid={`${deviceType}californiaResident`}
            href={`${process.env.REACT_APP_WEBSITE}/california-consumer-privacy-act-ccpa-request/`}
            rel="noreferrer"
            className="hrefTag"
          >
            <Typography className="normalFooterFontNew">
              For California Residents Do Not Sell My personal Information
            </Typography>
          </a>
        </div>
        <div className="footer-content footerSiteMap">
          <a
            data-testid="sitemap"
            href={`https://${process.env.REACT_APP_HOST_NAME}/sitemap.xml`}
            rel="noreferrer"
            target="_blank"
            className="hrefTag"
          >
            <Typography className="normalFooterFontNew">Sitemap</Typography>
          </a>
        </div>
      </>
    )
  }
  const legalPrivacyMenu = (deviceType, menuLink) => {
    return (
      <>
        <Grid className="linkHeadingsNew">
          <strong>Legal and Privacy</strong>
        </Grid>
        <div className="footer-content">
          <NavLink to="/communityGuidelines" className="hrefTagNew" data-testid={`${deviceType}communityGuidelines`}>
            <Typography className="normalFooterFontNew">Community Guidelines</Typography>
          </NavLink>
        </div>
        <div className="footer-content">
          <a
            href={`${process.env.REACT_APP_WEBSITE}/resources/legal/${menuLink}`}
            rel="noreferrer"
            className="hrefTag"
            data-testid={`${deviceType}privacyStatement`}
          >
            <Typography className="normalFooterFontNew">Privacy Statement</Typography>
          </a>
        </div>
      </>
    )
  }
  const californiaResidentsMenu = () => {
    return (
      <>
        <Grid className="linkHeadingsNew">
          <strong>California Residents</strong>
        </Grid>
        <Grid>
          <Typography className="normalFooterFontNew footerSiteMap">
            <span>Loans made or arranged pursuant to a California Financing Law license.</span>
          </Typography>
        </Grid>
      </>
    )
  }
  const vaResidentsMenu = () => {
    return (
      <>
        <Grid className="linkHeadingsNew">
          <strong>VA Residents</strong>
        </Grid>
        <Grid>
          <Typography className="normalFooterFontNew footerSiteMap">
            <span>
              Mariner Finance of Virginia. LLC, Licensed by the Virginia State Corporation Commission, Consumer Finance
              Company License No.CFI-114.
            </span>
          </Typography>
        </Grid>
      </>
    )
  }
  //View Part
  return (
    <div className="mainDivNormalFooter" data-testid="postlogin_footer_component">
      <footer style={{ background: '#013474' }}>
        <Grid className="footerContainer">
          <Grid className="branchLocatorFooterWrapNew" container>
            <Grid container style={{ paddingBottom: '4%' }}>
              <img
                type="image"
                src={whiteLogo}
                alt="logo image"
                className="privacyStatementLogo "
                style={{ width: '200px', height: 'auto' }}
              />
              <Grid className="footerTopLogoMobile" container>
                <Grid item xs={6}>
                  {badge ? <img type="image" src={badge} alt="photo" style={{ width: '100%', height: 'auto' }} /> : ''}
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {housingImage ? (
                    <img type="image" src={housingImage} alt="logo image" style={{ width: '30%', height: 'auto' }} />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="mobileViewContent">
              <Grid item xs={12} sm={12} md={12} lg={3} className="normalFooterFontSection">
                {legalPrivacyMenu('mobile', '#mobile-fusion-tab-privacystatement')}
                {footerMenuList.map((footerMenu, index) =>
                  footerMenu.testID !== 'licenseDisclosure' ? (
                    <div className="footer-content" key={`mobilefootermenu${Math.random() * 1000}`}>
                      <NavLink to={`/${footerMenu.link}`} className="hrefTag" data-testid={`web${footerMenu.testID}`}>
                        <Typography className="normalFooterFontNew">{footerMenu.menuText}</Typography>
                      </NavLink>
                    </div>
                  ) : (
                    <div className="footer-content" key={`mobilefootermenu${Math.random() * 1000}`}>
                      <a
                        href={`${process.env.REACT_APP_WEBSITE}/${footerMenu.link}`}
                        rel="noreferrer"
                        className="hrefTag"
                        data-testid={`web${footerMenu.testID}`}
                      >
                        <Typography className="normalFooterFontNew">{footerMenu.menuText}</Typography>
                      </a>
                    </div>
                  )
                )}
                {californiaFooterMenu('mobile')}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} className="normalFooterFontSection">
                {californiaResidentsMenu()}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} className="">
                {vaResidentsMenu()}
              </Grid>
            </Grid>
            <Grid className="webViewContent">
              <Grid item xs={12} sm={12} md={3} lg={3} className="normalFooterFontSection">
                {legalPrivacyMenu('web', 'privacy-statement/')}
                {footerMenuList.map((footerMenu, index) =>
                  footerMenu.testID !== 'licenseDisclosure' ? (
                    <div className="footer-content" key={`webfootermenu${Math.random() * 1000}`}>
                      <NavLink
                        to={`/${footerMenu.link}`}
                        className="hrefTag"
                        data-testid={`mobile${footerMenu.testID}`}
                      >
                        <Typography className="normalFooterFontNew">{footerMenu.menuText}</Typography>
                      </NavLink>
                    </div>
                  ) : (
                    <div className="footer-content" key={`webfootermenu${Math.random() * 1000}`}>
                      <a
                        href={`${process.env.REACT_APP_WEBSITE}/${footerMenu.link}`}
                        rel="noreferrer"
                        className="hrefTag"
                        data-testid={`mobile${footerMenu.testID}`}
                      >
                        <Typography className="normalFooterFontNew">{footerMenu.menuText}</Typography>
                      </a>
                    </div>
                  )
                )}
                {californiaFooterMenu('web')}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="normalFooterFontSection">
                {californiaResidentsMenu()}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={5} className="">
                {vaResidentsMenu()}
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Grid>
              <div className="mobileLocationWrap">
                <LocationOnOutlinedIcon />
                <p className="NormalLeftAlignAddressNew">8211 Town Center Drive, Nottingham, MD 21236.</p>
              </div>

              <div className="mobileLocationWrap">
                <PhoneOutlinedIcon />
                <a href="tel:+18773102373" className="hrefTagNew ">
                  &nbsp;877-310-2373
                </a>
              </div>
              <div className="mobileAddressWrap">
                <p className="NormalLeftAlignAddressNew">
                  Mariner Finance, LLC, NMLS No. 166564{' '}
                  <span
                    data-testid="webconsumerAccessOnclick"
                    className="spanLeftAlignAddress"
                    onClick={handleOpenConsumer}
                  >
                    (www.nmlsconsumeraccess.com)
                  </span>
                </p>
              </div>
            </Grid>
            <Grid container className="bottomSectionWrap">
              <Grid className="privacyStatementWeb" item xs={12} sm={12} md={3} lg={3}>
                {badge ? <img type="image" src={badge} alt="photo" style={{ width: '70%', height: 'auto' }} /> : ''}
              </Grid>
              <Grid className="footerPadding footerTextInfo" item xs={12} sm={12} md={7} lg={7}>
                <div>
                  <p className="NormalLeftAlignAddressNew">
                    Mariner Finance, LLC, NMLS No. 166564{' '}
                    <span
                      data-testid="consumerAccessOnclick"
                      className="spanLeftAlignAddress"
                      onClick={handleOpenConsumer}
                    >
                      (www.nmlsconsumeraccess.com)
                    </span>
                  </p>
                  <p className="NormalLeftAlignAddressNew">8211 Town Center Drive, Nottingham, MD 21236.</p>
                  <p className="NormalLeftAlignAddressNew">
                    Customer Service{' '}
                    <a href="tel:+18773102373" className="hrefTagNew ">
                      &nbsp;+1 877-310-2373
                    </a>
                    .
                  </p>
                </div>
              </Grid>

              <Grid className="footerPadding FooterHousingLogo privacyStatementWeb" item xs={12} sm={12} md={2} lg={2}>
                {housingImage ? <img type="image" src={housingImage} alt="logo image" /> : ''}
              </Grid>
            </Grid>
            <Grid container className="copyRightSection">
              <Grid item xs={12} sm={12} md={10} lg={10} className="privacyStatementTextWeb">
                <span data-testid="copyrightText" className="copyrightSpan">
                  &copy; {new Date().getFullYear()} Mariner Finance All rights reserved.
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Grid className="socialIconsWrapNormalFooter">
                  <span data-testid="facebookIcon">
                    <IconButton
                      id="facebookIcon"
                      data-testid="facebookButton"
                      aria-label="facebookIcon"
                      onClick={() => {
                        handleOpensocialMedia('https://www.facebook.com/MarinerFinance/')
                      }}
                      className="socialIconsNormalFooter"
                    >
                      <img type="image" src={fbIcon} alt="FB image" />
                    </IconButton>
                  </span>
                  <span data-testid="linkedInIcon">
                    <IconButton
                      data-testid="linkedInButton"
                      id="linkedIcon"
                      aria-label="linkedIcon"
                      onClick={() => {
                        handleOpensocialMedia('https://www.linkedin.com/company/mariner-finance/')
                      }}
                      className="socialIconsNormalFooter"
                    >
                      <img type="image" src={linkedInIcon} alt="Linkedin image" />
                    </IconButton>
                  </span>
                  <span data-testid="twitterIcon">
                    <IconButton
                      id="twitterIcon"
                      data-testid="twitterButton"
                      aria-label="twitterIcon"
                      onClick={() => {
                        handleOpensocialMedia('https://twitter.com/MarinerFinance')
                      }}
                      className="socialIconsNormalFooter"
                    >
                      <img type="image" src={TwitterIcon} alt="Linkedin image" />
                    </IconButton>
                  </span>
                  <span data-testid="instagramIcon">
                    <IconButton
                      data-testid="Button"
                      id="instagramIcon"
                      aria-label="instagramIcon"
                      onClick={() => {
                        handleOpensocialMedia('https://www.instagram.com/marinerfinancellc/?hl=en')
                      }}
                      className="socialIconsNormalFooter"
                    >
                      <img type="image" src={InstagramIcon} alt="Linkedin image" />
                    </IconButton>
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10} className="privacyStatementMobile">
                <span data-testid="copyrightText" className="copyrightSpanNormalFooter">
                  &copy; {new Date().getFullYear()} Mariner Finance All rights reserved.
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
      <ConsumerDialog consumer={consumer} onChange={setConsumer} />
      <SocialMediaDialog URL={URL} socialMedia={socialMedia} onChange={setSocialMedia} />
    </div>
  )
}

/**
 * Component for the Forgot Password page.
 * Allows users to request a password reset link by entering their email address.
 * Uses Formik for form handling and Yup for form validation.
 * Displays error messages using react-toastify.
 * @returns {JSX.Element} JSX element that displays the Forgot Password page.
 */
import React, { useState } from 'react'
import { useFingerprint } from '../../../hooks/useFingerprint'
import { Grid, Paper, Typography, Box } from '@mui/material'

import { EmailTextField, ButtonPrimary } from '../../FormsUI'
import { NavLink, useNavigate, Link } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import { useStylesForgotPassword } from './Style'
import PasswordLogo from '../../../assets/icon/confirmation 2.png'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { FormValidationRules } from '../../lib/FormValidationRule'
import ForgotPasswordMessages from '../../../assets/data/ForgetPasswordMessages.json'
import globalMessages from '../../../assets/data/globalMessages.json'
import { requestPasswordReset } from '../../Controllers/PasswordRecoveryController'

function ForgotPassword() {
  const navigate = useNavigate()
  const classes = useStylesForgotPassword()
  const [loading, setLoading] = useState(false)
  //Yup validations for all the input fields
  let formValidation = new FormValidationRules()
  const validationSchema = yup.object({
    email: formValidation.email().required(globalMessages.Email_is_required),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, validateForm }) => {
      try {
        const errors = await validateForm()
        if (Object.keys(errors).length > 0) {
          setSubmitting(false)
          return
        }
        if (loading) return toast.error(globalMessages.Please_wait_while_we_process_your_request)
        setLoading(true)
        const response = await requestPasswordReset(values.email)
        if (response) {
          navigate('/forgot-password-resend-link', { state: { email: values?.email } })
        } else {
          toast.error(globalMessages.An_error_occurred_processing_request)
        }
      } catch (error) {
        if (error?.message.includes('contact your branch')) {
          toast.error(globalMessages.Contact_Support)
          navigate('/login')
        } else if (
          error?.response?.data?.error?.message == null ||
          error?.response?.data?.error?.message === undefined
        ) {
          toast.error(globalMessages.An_error_occurred_processing_request)
        } else {
          toast.error(error)
        }
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  //Preventing space key
  const preventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }
  const andLogic = (valueOne, valueTwo) => {
    return valueOne && valueTwo
  }
  return (
    <div className={classes.forgotPasswordRoot}>
      <ScrollToTop />
      <Grid container className={classes.forgotPasswordContainer}>
        <Grid item xs={11} md={8}>
          <Paper className={classes.forgotPasswordPaper}>
            <Link className={classes.arrowBack} to="/login">
              <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
            </Link>
            <Grid item xs={12} md={7} className={classes.mainContentArea}>
              <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                <Grid container className={classes.rotatingIcon}>
                  <img src={PasswordLogo} alt="PasswordLogo" />
                </Grid>
                <Typography className={classes.title} data-testid="title">
                  Forgot Password
                </Typography>
                <Typography className={classes.directiveText}>{ForgotPasswordMessages.Enter_mail_address}</Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid style={{ marginBottom: '10px' }}>
                  <EmailTextField
                    id="email"
                    name="email"
                    design="new"
                    label="Email Address*"
                    materialProps={{ maxLength: '100' }}
                    onKeyDown={preventSpace}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={andLogic(formik.touched.email, Boolean(formik.errors.email))}
                    helperText={andLogic(formik.touched.email, formik.errors.email)}
                  />
                </Grid>
                <Grid className={classes.errorValidationColumn} sx={{ textAlign: 'right' }}>
                  <NavLink className={classes.helperText} to="/account-recovery">
                    <span className={classes.helperText}>Forgot email?</span>
                  </NavLink>
                </Grid>
                <Grid className={classes.footer}>
                  <ButtonPrimary
                    type="submit"
                    stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                    disabled={!formik.isValid || !formik.dirty || loading}
                  >
                    Send Link
                    {loading && <AutorenewIcon className={classes.autoRenew} />}
                  </ButtonPrimary>

                  <Typography className={classes.footerText}>
                    Don&lsquo;t have an account?&nbsp;
                    <Link to="/register" className={classes.footerLink}>
                      Create Account
                    </Link>
                  </Typography>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPassword

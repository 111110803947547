import { encode as base64_encode } from 'base-64'
import Cookies from 'js-cookie'
import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import { encryptAES } from '../lib/Crypto'
import ErrorLogger from '../lib/ErrorLogger'
const utf8 = require('utf8')

const moment = require('moment')
const moment_timezone = require('moment-timezone')
let addVal = moment_timezone().tz('America/New_York').isDST() ? 4 : 5
/***** Login method *****/
export default async function LoginController(email, password, clientIp, longitude, latitude, deviceType) {
  try {
    let url = 'login_customer'
    let param = ''
    let data = {
      email: email,
      password: password,
      clientIp: clientIp,
      deviceType: deviceType,
      longitude: longitude || '',
      latitude: latitude || '',
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    let loginMethod = await APICall(url, param, data, method, addAccessToken)
    Cookies.set('userCustomerId', loginMethod?.data?.user?.attributes?.sor_data?.customer_id)
    Cookies.set('userApplicantId', loginMethod?.data?.user?.attributes?.sor_data?.applicant_id)
    Cookies.set('userLastLogin', loginMethod?.data?.user?.extensionattributes?.login?.timestamp_date)

    return loginMethod
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_LoginController_API, error)
  }
}

/***** Register method *****/
export async function RegisterController(registerData) {
  try {
    let api = 'register_customer'
    let param = ''
    let data = registerData
    let method = 'POST'
    let addAccessToken = false
    let authId = process.env.REACT_APP_AUTH_ACCESS_ID
    let authPW = process.env.REACT_APP_AUTH_ACCESS_PASSWORD
    let idBytes = utf8.encode(authId)
    let pwBytes = utf8.encode(authPW)
    let idEncoded = base64_encode(idBytes)
    let pwEncoded = base64_encode(pwBytes)

    //API call
    return await APICall(api, param, data, method, addAccessToken, idEncoded, pwEncoded)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_RegisterController_API, error)
  }
}

const setCookiesPostLogin = (retVal, now, values, login_date, mfaData, remMe) => {
  Cookies.set(
    'token',
    JSON.stringify({
      isLoggedIn: true,
      apiKey: retVal?.data?.user?.extensionattributes?.login?.jwt_token,
      setupTime: now,
      applicantGuid: retVal?.data?.user?.attributes?.sor_data?.applicant_guid,
      isMFA: retVal?.data?.user?.extensionattributes?.MFA ?? false,
      isMFACompleted: false,
    })
  )
  Cookies.set('cred', encryptAES(JSON.stringify({ email: values?.email, password: values?.password })))
  Cookies.set('email', values?.email)
  Cookies.set('firstName', retVal?.data?.user?.firstname)
  Cookies.set('lastName', retVal?.data?.user?.lastname)
  Cookies.set('userId', retVal?.data?.user?._id)
  Cookies.set(
    'profile_picture',
    retVal?.data?.user?.mobile?.profile_picture ? retVal?.data?.user?.mobile?.profile_picture : ''
  )
  Cookies.set('login_date', login_date)
  Cookies.set('userToken', retVal?.data?.user?.attributes?.UserToken)
  Cookies.set('temp_opted_phone_texting', '')
  Cookies.set(
    'rememberMe',
    remMe ? JSON.stringify({ selected: true, email: values?.email }) : JSON.stringify({ selected: false, email: '' })
  )
  Cookies.set('mfaDetails', JSON.stringify(mfaData))
}

export const fsSetIdentity = function async() {
  const fsIdentifyDetails = Cookies.get('fsIdentifyDetails') ? Cookies.get('fsIdentifyDetails') : '{}'
  const fsIdentify = JSON.parse(fsIdentifyDetails)
  let guid = fsIdentify?.GUID ?? ''
  let displayName = fsIdentify?.displayName ?? ''
  if (window.FS && guid && displayName) {
    window.FS.identify(guid, {
      GUID: guid,
      displayName: displayName,
    })
  }
}

export const handleSuccessLogin = (retVal, values, mfaData, rememberMe, queryClient, navigate, location) => {
  let login_date = retVal?.data?.user?.extensionattributes?.login?.last_timestamp_date
    ? moment(retVal.data.user.extensionattributes.login.last_timestamp_date)
        .subtract(addVal, 'hours')
        .format('MM/DD/YYYY')
    : ''
  let now = new Date().getTime()
  // On login success storing the needed data in the local storage
  setCookiesPostLogin(retVal, now, values, login_date, mfaData, rememberMe)
  Cookies.set('forceResetPassword', retVal?.data?.user?.attributes?.password_reset ?? false)
  Cookies.set('expiredPassword', retVal?.data?.passwordExpired)
  queryClient.removeQueries()
  fsSetIdentity()
  if (retVal?.data?.isRegistering) {
    navigate('/MFA-SelectSecurityQuestions', { state: { currentFlow: true, isRegistering: true } })
  } else if (
    retVal?.data?.user?.extensionattributes?.LockUserByMFACounter === 1 &&
    retVal?.data?.user?.extensionattributes?.MFA
  ) {
    navigate('/MFA-phoneNumber', { state: mfaData })
  } else if (retVal?.data?.user?.extensionattributes?.MFA) {
    Cookies.set('mfaDetails', mfaData)
    navigate('/MFA', { state: mfaData })
  } else if (
    retVal?.data?.user?.extensionattributes?.LockUserByMFACounter > 0 &&
    !retVal?.data?.user?.extensionattributes?.securityQuestionsSaved &&
    !retVal?.data?.user?.extensionattributes?.MFA
  ) {
    navigate('/MFA-SelectSecurityQuestions', { state: { currentFlow: true } })
  } else {
    retVal?.data?.user?.attributes?.password_reset
      ? navigate('/resetpassword', { state: { Email: values?.email } })
      : navigate(location.state?.redirect ? location.state?.redirect : '/customers/accountoverview')
  }
  if (location.state?.activationToken) {
    navigate(0)
  }
}

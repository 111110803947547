import React, { useState } from 'react'
import {
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { useStylesMFA } from './Style'
import PropTypes from 'prop-types'

const PhoneNumberPopUp = ({ phoneNumberList, setSelection }) => {
  const classes = useStylesMFA()
  const [value, setValue] = useState('')
  const handleChange = (event) => {
    setValue(event.target.value)
  }
  const securityCode = (number) => {
    return (
      <div data-testid="securitycode_func" className={classes.securityCodeText}>
        <span>{`Get code on (***) ***-${number ? number.substr(-4) : ''}`}</span>
      </div>
    )
  }

  return (
    <Grid data-testid="PhoneNumberPopUp_component">
      <DialogTitle className={classes.subTitle}>
        Select from one of your preferred phone number(s) to receive your passcode.
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroupWrap}
            id="textAndCallss"
            aria-label="method"
            name="method"
            value={value}
            onChange={handleChange}
          >
            {phoneNumberList?.map((phoneNumber, index) => (
              <FormControlLabel
                key={index}
                data-testid="phone_List"
                className={classes.radioGroup}
                value={phoneNumber.number}
                control={
                  <Radio
                    className={classes.customRadio}
                    data-testid={`radio_primary_phone_${index}`}
                    onClick={() => setSelection(`${phoneNumber.number}`)}
                  />
                }
                label={securityCode(phoneNumber.number)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </Grid>
  )
}

PhoneNumberPopUp.propTypes = {
  setSelection: PropTypes.func,
  phoneNumberList: PropTypes.array,
}

export default PhoneNumberPopUp

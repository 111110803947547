import { makeStyles } from '@mui/styles'
import overlayImage from '../../../../assets/images/mariner_overlay_Logo.png'

const useStylesVerifyAccount = makeStyles((theme) => ({
  paper: {
    maxWidth: '600px',
    padding: '30px 50px',
    margin: '100px 0px',
    position: 'relative',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
  },
  passwordLogo: {
    justifyContent: 'center',
    padding: '20px',
  },
  title: {
    fontSize: '25px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black',
    margin: '0 0 3% 0',
  },
  sendLinkButton: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '1px 0 0 0',
  },
  userLogo: {
    justifyContent: 'center',
    padding: '20px 20px 20px 0px',
  },
  twoStepParagraph: {
    width: '80%',
    margin: ' 25px auto',
    textAlign: 'justify',
    fontWeight: 400,
    fontSize: '1rem',
    color: 'black',
    textAlignLast: 'center',
  },
  otpNumber: {
    padding: '0px 5px',
    textAlign: 'center',
  },
  resetText: {
    fontSize: '0.844rem',
    textAlign: 'center',
    margin: '30px 0px 0px 0px',
    color: '#6d6d6d',
  },
  labelText: {
    color: '#6d6d6d',
  },
  otpWrap: {
    maxWidth: '60%',
    margin: 'auto',
  },
}))

export { useStylesVerifyAccount }

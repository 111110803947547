import { Typography, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { ButtonPrimary } from '../../FormsUI'
// import './MultiFactorAuthentication.css'
import { useStylesMFA } from './Style'
import PasswordLogo from '../../../assets/icon/confirmation 2.png'
import { saveSecurityAnswer } from '../../Controllers/MFAController'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import ScrollToTop from '../CheckMyOffers/ScrollToTop'
import Messages from '../../../assets/data/globalMessages.json'
import { useFingerprint } from '../../../hooks/useFingerprint'

//Yup validations for all the input fields
const validationSchema = yup.object({
  answer: yup
    .string('Enter your answer')
    .trim()
    .max(30, 'Should be maximum of 30 characters')
    .required('Enter your answer'),
})

const MFASecurityQuestions = () => {
  const classes = useStylesMFA()
  let location = useLocation()
  const navigate = useNavigate()
  const [selectedQuestions, setSelectedQuestions] = useState()
  const [counter, setCounter] = useState(0)
  const { visitorId } = useFingerprint()
  useEffect(() => {
    if (!location?.state?.mfaSecurityQuestions) {
      navigate('/customers/accountOverview')
    }
    getMFAQuestion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //Form Submission
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answer: '',
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const userEmail = Cookies.get('email')
      let redirectURL = Cookies.get('afterMFARedirect')
      let answerData = {
        email: userEmail,
        deviceType: visitorId,
        securityQuestions: [
          {
            question_id: selectedQuestions?.question_id,
            answer: values.answer,
          },
        ],
        redirect: redirectURL,
      }
      // let getMfaPhone = Cookies.get('getPhoneFromUser') === 'true'
      let verify = await saveSecurityAnswer(answerData)
      if (verify?.data?.hasError === false && verify?.data?.statusCode === 200) {
        toast.success(verify?.data?.Message)
        navigate('/recovery-success')
      } else if (verify?.data?.hasError === true && verify?.data?.result === 'error') {
        setCounter(counter + 1)
        if (counter >= 2 || verify?.data?.Message.includes('locked')) {
          toast.error(Messages?.Contact_Support)
          navigate('/login')
        } else {
          toast.error(Messages?.Incorrect_response)
        }
      } else {
        toast.error('Network error')
      }
    },
  })

  const getMFAQuestion = () => {
    if (location?.state?.mfaSecurityQuestions) {
      let question = location?.state?.mfaSecurityQuestions?.mfaDetails?.securityQuestions
      let questionArray = question[Math.floor(Math.random() * question?.length)]
      setSelectedQuestions(questionArray)
    }
  }

  // const preventSpace = (event) => {
  //   if (event.keyCode === 32) {
  //     event.preventDefault()
  //   }
  // }

  return (
    <div className={classes.forgotPasswordRoot} data-testid="mfa-security-questions-component">
      <ScrollToTop />
      <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
        <Paper className={classes.forgotPasswordPaper}>
          <Grid item xs={12} md={8} width="100%" className={classes.mainContentArea}>
            <Link className={classes.arrowBack} to="/MFA_verify">
              <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
            </Link>

            <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
              <Grid container className={classes.rotatingIcon}>
                <img src={PasswordLogo} alt="PasswordLogo" />
              </Grid>
              <Typography className={classes.title} data-testid="title">
                Verify Account
              </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Box display="flex" flexDirection="column" flexGrow={1} gap={1} paddingBottom={4}>
                <Typography className={classes.securityQuestion}>Security Question</Typography>
                <Typography style={{ color: '#585858' }}>{selectedQuestions?.question}*</Typography>
                <TextField
                  id="Answer"
                  name="answer"
                  data-testid="security-input"
                  design="new"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={formik.values.answer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 30 }}
                  error={formik.touched.answer && Boolean(formik.errors.answer)}
                  helperText={formik.touched.answer && formik.errors.answer}
                />
                <Typography className={classes.disclosureText}>Answers are not case sensitive</Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                <ButtonPrimary
                  stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                  type="submit"
                  data-testid="submit"
                  disabled={!formik.values.answer}
                >
                  Confirm
                </ButtonPrimary>
              </Box>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default MFASecurityQuestions

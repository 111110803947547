import { makeStyles } from '@mui/styles'
import overlayImage from '../../../../assets/images/mariner_overlay_Logo.png'

const useStylesForgotPassword = makeStyles((theme) => ({
  arrowBack: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '1.5rem',
  },

  forgotPasswordRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  forgotPasswordContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '3rem auto',
  },
  forgotPasswordPaper: {
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
  },
  mainContentArea: {
    padding: '3rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '400',
    color: '#000',
    fontFamily: "'Muli', sans-serif !important",
  },
  subTitle: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: '400',
    color: '#000',
    fontFamily: "'Muli', sans-serif !important",
  },
  directiveText: {
    textAlign: 'center',
    color: '#000',
    fontSize: '1.06rem',
    fontFamily: "'Muli', sans-serif !important",
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 2rem 0 0rem',
  },
  //   closeButton: {
  //  right:'1',
  //  top:'2rem',
  //   },
  inputLabel: {
    fontSize: '.90rem',
    color: '#585858',
    bottom: '.5rem',
    fontFamily: "'Muli', sans-serif !important",
    width: '100%',
  },
  dialogRoot: {
    margin: '0 10px',
  },

  dialogPaper: {
    // This style is for the Paper component inside Dialog
    // position:'relative',
    width: '50%', // Off on Mobile
    height: 'auto', // Adjusting the height as well
    maxWidth: 'none', // Make sure the dialog doesn't have a max-width
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%', // makes it responsive for smaller screens
    },
  },

  dialogContainer: {
    width: '40rem', // Off on Mobile
    height: 'auto',

    padding: '0 0 2rem 0',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%', // makes it responsive for smaller screens
    },
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },

  helperText: {
    textDecoration: 'none',
    color: '#013474',
  },
  radioGroup: {
    padding: '0 0 .25rem 0',
    color: '#585858',
  },
  customRadio: {
    '& .MuiSvgIcon-root': {
      color: '#585858',
      width: '20px',
      height: '20px',
    },
  },
  customLabelField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1.5px solid #013474',
        // custom color
      },
      '&:hover fieldset': {
        borderColor: '#013474', // custom color for hover state
      },
      '&.Mui-focused fieldset': {
        borderColor: '#013474', // custom color when focused
      },
    },
  },
  errorValidationColumn: {
    padding: '0',
  },
  disclosure: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem auto',
    flexDirection: 'column',
  },
  securityQuestion: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    fontSize: '1.25rem',
    fontWeight: '400',
    color: '#000',
    fontFamily: "'Muli', sans-serif !important",
  },
  resendForm: {
    padding: '2rem 0 0rem 0',
  },
  subText: {
    padding: '1rem 0 0rem 0',
  },
  disclosureText: {
    textAlign: 'center',
    lineHeight: '1.5',
    fontSize: '0.938rem',
    color: '#000',
    fontFamily: "'Muli', sans-serif !important",
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3rem 0',
  },
  footerText: {
    fontSize: '0.938rem',
    color: '#585858',
    margin: '.5rem auto',
    fontFamily: "'Muli', sans-serif !important",
  },
  bottomNoteText: {
    fontSize: '0.938rem',
    margin: '-3rem auto',
    textAlign: 'center',
  },
  footerLink: {
    color: '#0F4EB3',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '0.938rem',
    textDecoration: 'none',
    fontFamily: "'Muli', sans-serif !important",
  },

  passwordResetModalButtonIcon: {
    display: 'none',
  },
  rotatingIcon: {
    animation: 'spin 2s linear infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  autoRenew: {
    animation: '$spin 1s linear infinite',
  },

  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },

  // Start of old styles
  paper: {
    margin: '100px 0px',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
    height: '500px',
    padding: '30px 11%',
  },
  paperStyle2: {
    margin: '60px 0px',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
    padding: '40px 13%',
  },
  stackSection: {
    marginBottom: '30px',
    alignItems: 'center',
  },
  mailIdDisplay: {
    fontWeight: 'bold',
  },
  textPartPadding: {
    padding: '20px 0',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '25px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black',
    margin: '0 0 3% 0',
  },
  emailNameStyle: {
    padding: 0,
  },
  sendLinkButton: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '5% 0 0 0',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
  linkDesign: {
    color: '#0F4EB3',
    cursor: 'pointer',
    fontSize: '0.938rem',
  },
  register: {
    fontSize: '0.844rem',
    textDecoration: 'none',
    color: '#0F4EB3',
    fontFamily: "'Muli', sans-serif !important",
    marginBottom: '0px',
  },
  noUnderLine: {
    textDecoration: 'none',
  },

  ssnInput: {
    marginBottom: '50px',
  },
  ssnField: {
    '& .MuiInputBase-input': {
      paddingRight: '0px',
      paddingLeft: '0px',
      textAlign: 'center',
    },
  },
  resendButton: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '3% 0 0 0',
  },
  receiveEmailText: {
    fontSize: '14px',
    fontWeight: '600',
  },
  termsText: {
    fontSize: '13px',
    padding: '10px 0',
  },
  titleText: {
    fontSize: '23px',
    fontWeight: '600',
    textAlign: 'center',
    color: 'black',
  },
  resendGrid: {
    padding: '20px 60px 0px 60px',
  },
  receiveEmailGrid: {
    textAlign: 'center',
    width: '100%',
    margin: '5% 0px 0px 0px',
  },
  passwordResetTextGrid: {
    textAlign: 'center',
    width: '100%',
    margin: '0px 0px 0px 0px',
    padding: '40px 0px',
  },
  passwordResetText: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
  },
  passwordLogo: {
    justifyContent: 'center',
    padding: '20px',
  },
  createPasswordPaper: {
    margin: '80px 0',
    padding: '30px 18%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `rgba(255, 255, 255, .8)`,
    color: theme.palette.text.secondary,
    boxShadow: `0 16px 24px 2px rgb(0 0 0 / 14%),
0 6px 30px 5px rgb(0 0 0 / 12%),
0 8px 10px -7px rgb(0 0 0 / 20%)`,
  },
  subtitle: {
    fontSize: '16px',
    textAlign: 'center',
    color: '#171717',
    fontWeight: '400',
  },
  logInGrid: {
    paddingTop: '30px',
    margin: '0',
  },
  passwordWrap: {
    width: '100%',
    padding: '0px 0px 16px 0px',
    marginBottom: 10,
  },
  resetButton: {
    textAlign: 'center',
  },
  resetImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  ssnTextField: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    color: 'black',
    paddingLeft: '10px',
  },
  textFieldSpace: {
    marginTop: '20px',
    color: '#013474',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  verifyPaper: {
    margin: '60px 0px',
    padding: '40px 5%',
    backgroundImage: `url(${overlayImage})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20%',
  },
  accountRecoverLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyPaperStyle: {
    padding: '10px 8%',
  },
}))

export { useStylesForgotPassword }

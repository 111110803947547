import ErrorLogger from '../lib/ErrorLogger'
import globalMessages from '../../assets/data/globalMessages.json'
import Moment from 'moment'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Cookies from 'js-cookie'
/***** get IP from cloudflare method *****/
export default async function getClientIp(_rountingNumber) {
  try {
    let ipResponse = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    ipResponse = await ipResponse.text()
    let ipRegex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/
    return ipResponse.match(ipRegex)[0]
  } catch (err) {
    return null
  }
}
export async function getTimeZoneDetails(latitude, longitude) {
  try {
    let tempTime = Moment().valueOf() / 1000
    let response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${tempTime}&key=${process.env.REACT_APP_SECKey}`
    )
    response = await response.text()
    return JSON.parse(response)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Google_Time_Zone_API, error)
  }
}
export function trimSpecialCharacters(value) {
  return value.replace(/-/g, '').replace(/\)/g, '').replace(/\(/g, '').replace(/ /g, '') || ''
}

export const phoneNumberMask = (values) => {
  if (values) {
    let phoneNumber = values.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    values = !phoneNumber[2]
      ? phoneNumber[1]
      : '(' + phoneNumber[1] + ') ' + phoneNumber[2] + (phoneNumber[3] ? '-' + phoneNumber[3] : '')
    return values
  }
  return ''
}

export const maskPhoneNumberWithAsterisk = (phoneNumberToMask) => {
  let firstNumber = phoneNumberToMask.slice(0, 10)
  return firstNumber.replace(/\d/g, '*') + phoneNumberToMask.slice(10)
}

export const SSNMask = (values) => {
  if (values) {
    let SSN = values.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
    values = !SSN[2] ? SSN[1] : SSN[1] + '-' + SSN[2] + (SSN[3] ? '-' + SSN[3] : '')
    return values
  }
  return ''
}

export const maskSSNWithAsterisk = (SSNToMask) => {
  let firstNumber = SSNToMask.slice(0, 7)
  return firstNumber.replace(/\d/g, '*') + SSNToMask.slice(7)
}

export const handleDateOffset = (utcTime) => {
  const tzoffset = utcTime.getTimezoneOffset() * 60000
  return new Date(utcTime.getTime() + tzoffset)
}

export const getDeviceFingerprint = async () => {
  const fp = await FingerprintJS.load()
  const { visitorId } = await fp.get()
  let combinedFingerprint = visitorId + ' ' + navigator.userAgent
  return { visitorId: combinedFingerprint }
}

export const updateMFAStatusAsCompleted = () => {
  const tokenString = Cookies.get('token') ? Cookies.get('token') : '{ }'
  let userToken = JSON.parse(tokenString)
  userToken.isMFACompleted = true
  Cookies.set('token', JSON.stringify(userToken)) //Setting MFA Flag to complete Login
}

export const updateUserIsMFAChallenged = () => {
  const tokenString = Cookies.get('token') ? Cookies.get('token') : '{ }'
  let userToken = JSON.parse(tokenString)
  userToken.isMFA = false
  Cookies.set('token', JSON.stringify(userToken)) //Setting isMFA Challenged Flag
}

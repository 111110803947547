import React, { useState, useEffect } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useFingerprint } from '../../../hooks/useFingerprint'
import { ButtonPrimary } from '../../FormsUI'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useStylesForgotPassword } from './Style'
import PasswordLogo from '../../../assets/icon/confirmation 2.png'
import ScrollToTop from '../CheckMyOffers/ScrollToTop'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormValidationRules } from '../../lib/FormValidationRule'
import { requestPasswordReset } from '../../Controllers/PasswordRecoveryController'
import globalMessages from '../../../assets/data/globalMessages.json'
import ForgotPasswordMessages from '../../../assets/data/ForgetPasswordMessages.json'

/**
 * This component renders the Forgot Password page and allows the user to resend the password reset link.
 * @returns {JSX.Element} The Forgot Password page component.
 */
function ForgotPassword() {
  const classes = useStylesForgotPassword()
  const navigate = useNavigate()
  let location = useLocation()
  let userEmail = location?.state?.email || ''
  const { visitorId } = useFingerprint()
  const [loading, setLoading] = useState(false)
  const [isResendLink, setIsResendLink] = useState(false)

  //Yup validations for all the input fields
  let formValidation = new FormValidationRules()
  const validationSchema = yup.object({
    email: formValidation.email(),
  })

  useEffect(() => {
    if (!userEmail) {
      navigate('/forgot-password')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {
      email: userEmail,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (loading) return toast.error(globalMessages.Please_wait_while_we_process_your_request)
        setLoading(true)
        const response = await requestPasswordReset(values.email)

        //  Response body shouldn't be visible, exposing too much information. Please consult issue number 1.

        if (response) {
          toast.success(response.data?.message || 'A new password reset has been sent')
        } else {
          toast.error(globalMessages.An_error_occurred_processing_request)
        }
      } catch (error) {
        if (error?.response?.data?.error?.message == null || error?.response?.data?.error?.message === undefined) {
          toast.error(globalMessages.An_error_occurred_processing_request)
        } else {
          toast.error(error)
        }
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className={classes.forgotPasswordRoot}>
      <ScrollToTop />
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={11} md={8} className={classes.forgotPasswordContainer}>
          <Paper className={classes.forgotPasswordPaper}>
            <Grid item xs={12} md={9} className={classes.mainContentArea}>
              <Box display="flex" flexDirection="column" gap={2} textAlign="center">
                <Grid container className={classes.rotatingIcon}>
                  <img src={PasswordLogo} alt="PasswordLogo" />
                </Grid>
                <Typography className={classes.title} data-testid="title">
                  {isResendLink
                    ? `A new email has been sent again to ${userEmail} `
                    : `An email has been sent to ${userEmail} `}
                  with a link to reset your password
                </Typography>
              </Box>

              <Grid className={classes.footer}>
                <Typography className={classes.disclosureText} style={{ color: '#585858' }}>
                  Didn&lsquo;t receive an email?
                </Typography>
                <form onSubmit={formik.handleSubmit} className={classes.resendForm}>
                  <input
                    type="hidden"
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <ButtonPrimary
                    type="submit"
                    stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                    disabled={loading}
                  >
                    Resend Link
                    {loading && <AutorenewIcon className={classes.autoRenew} />}
                  </ButtonPrimary>
                </form>
              </Grid>

              {/* Text isn't showing  */}
              <Grid className={classes.footer}>
                <Typography className={classes.footerText}>{ForgotPasswordMessages.footerLink}</Typography>
                <Typography className={classes.bottomNoteText}>
                  {ForgotPasswordMessages.Password_resent_link}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPassword

import React, { useState, useEffect } from 'react'
import './MultiFactorAuthentication.css'
import Cookies from 'js-cookie'
import CheckLoginTimeout from '../../Layout/CheckLoginTimeout'
import { useMultiFactorAuthentication } from '../../../hooks/useMultiFactorAuthentication'
import { useLocation } from 'react-router-dom'
import MFASelection from './MFASelection'
import CheckLoginStatus from '../../App/CheckLoginStatus'
import CircularProgress from '@mui/material/CircularProgress'
import './mfa.css'

const MultiFactorAuthentication = () => {
  const location = useLocation()
  const loginToken = JSON.parse(Cookies.get('token') ? Cookies.get('token') : '{ }')
  const [mfaData, setMFAData] = useState()
  const { isLoading: loading_mfaData, data: mfaInfo } = useMultiFactorAuthentication()
  const [uniqueNumber, setUniqueNumber] = useState()
  const [disableSecurityQuestions, setDisableSecurityQuestions] = useState(false)
  let securityQuestionsSaved = mfaData?.mfaDetails?.securityQuestionsSaved
  Cookies.remove('otpSkip')
  Cookies.remove('kbaSkip')

  const mfaValidation = () => {
    let eligibleNumber = []
    mfaInfo?.data?.MFAInformation?.phone_numbers.forEach((element) => {
      eligibleNumber.push({
        number: element.trim(),
        type: 'phone_number',
      })
    })
    if (mfaInfo?.data?.MFAInformation) {
      let structureMFAdata = {
        mfaDetails: mfaInfo.data.MFAInformation,
      }

      let mfaPhoneCookie = Cookies.get('mfaPhone')
      let phoneFromCookie = mfaPhoneCookie ? mfaPhoneCookie.trim() : null
      if (phoneFromCookie) {
        eligibleNumber.push({
          number: phoneFromCookie,
          type: 'mfa_phone_texting',
        })
      }
      setMFAData(structureMFAdata)
      let uniqueEligibleNumbers = eligibleNumber?.filter(
        (eligibleNum, index, eligibleNumArray) =>
          eligibleNumArray.findIndex((phoneNum) => phoneNum.number === eligibleNum.number) === index
      )
      setUniqueNumber(uniqueEligibleNumbers)
    }
  }
  useEffect(() => {
    if (location?.state?.noKbaQuestions) {
      setDisableSecurityQuestions(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    mfaValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaInfo])

  return (
    <div data-testid="mfa_renders">
      <CheckLoginTimeout />
      {loginToken.isLoggedIn ? (
        <>
          {loading_mfaData ? (
            <div className="mfa_loadingSpinnerDiv" data-testid="mfa_loadingSpinnerDiv">
              <CircularProgress className="mfa_loadingSpinner" />
            </div>
          ) : (
            <MFASelection
              securityQuestionsSaved={securityQuestionsSaved}
              phoneNumberList={uniqueNumber}
              mfaDetails={mfaData}
              disableSecurityQuestions={disableSecurityQuestions}
            />
          )}
        </>
      ) : (
        <CheckLoginStatus />
      )}
    </div>
  )
}

export default MultiFactorAuthentication

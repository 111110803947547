// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#insideIframe iframe {
  position: relative !important;
}

.iframeStyle {
  height: 500px;
}

.formStyle {
  width: '100%';
}

.MuiInputAdornment-root .MuiButtonBase-root {
  padding: 0px 0px 0px 10px !important;
}

.formStyle {
  width: '100%';
}

.datePickerFormLabelStyle {
  padding-left: '2px';
  padding-bottom: '5px';
  text-align: left;
}

.rem1FSize {
  font-size: 1rem !important;
}

.globelPassword .MuiInput-underline .MuiInputAdornment-root {
  margin: 0;
  min-width: 55px;
  width: 55px;
  align-items: center;
  justify-content: center;
  max-height: 100% !important;
  height: 42px;
  padding: 0px 0px 0px 5px !important;
  border-radius: 10px !important;
}

.globelPassword .MuiInput-underline .MuiInputAdornment-root button {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  border-radius: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormsUI/iframe.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,YAAY;EACZ,mCAAmC;EACnC,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,8BAA8B;AAChC","sourcesContent":["#insideIframe iframe {\n  position: relative !important;\n}\n\n.iframeStyle {\n  height: 500px;\n}\n\n.formStyle {\n  width: '100%';\n}\n\n.MuiInputAdornment-root .MuiButtonBase-root {\n  padding: 0px 0px 0px 10px !important;\n}\n\n.formStyle {\n  width: '100%';\n}\n\n.datePickerFormLabelStyle {\n  padding-left: '2px';\n  padding-bottom: '5px';\n  text-align: left;\n}\n\n.rem1FSize {\n  font-size: 1rem !important;\n}\n\n.globelPassword .MuiInput-underline .MuiInputAdornment-root {\n  margin: 0;\n  min-width: 55px;\n  width: 55px;\n  align-items: center;\n  justify-content: center;\n  max-height: 100% !important;\n  height: 42px;\n  padding: 0px 0px 0px 5px !important;\n  border-radius: 10px !important;\n}\n\n.globelPassword .MuiInput-underline .MuiInputAdornment-root button {\n  height: 100%;\n  width: 100%;\n  padding: 0px !important;\n  border-radius: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrap table p {
  text-align: left !important;
}

@media only screen and (max-width: 400px) {
  .sendLetter br {
    display: none;
  }
}

#customeDialogBox p {
  text-align: justify;
}

.contentWrap div p a {
  color: #214476 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormsUI/RenderContent/Style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".contentWrap table p {\n  text-align: left !important;\n}\n\n@media only screen and (max-width: 400px) {\n  .sendLetter br {\n    display: none;\n  }\n}\n\n#customeDialogBox p {\n  text-align: justify;\n}\n\n.contentWrap div p a {\n  color: #214476 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import ErrorLogger from '../lib/ErrorLogger'
import { encode as base64_encode } from 'base-64'
const utf8 = require('utf8')

/***** Get Account overview details *****/
export default async function setAccountDetails() {
  try {
    let url = 'account_overview'
    let param = ''
    let data = Object.assign(localStorage?.getItem('user') ? { user: localStorage?.getItem('user') } : {})
    let method = 'GET'
    let addAccessToken = true

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_AccountDetails_API, error)
  }
}

export async function postFullStorySession(customer, fsSession, fsSessionKey) {
  try {
    //API
    let url = 'post_fullstory'
    let param = ''
    customer.fullstory = Object.assign({}, customer.fullstory, {
      [fsSessionKey]: {
        url: fsSession,
        date: new Date(),
      },
    })
    let data = {
      formdata: customer,
    }
    let method = 'POST'
    let addAccessToken = true
    // Get Id and Password to encode
    let authId = process.env.REACT_APP_AUTH_ACCESS_ID
    let authPW = process.env.REACT_APP_AUTH_ACCESS_PASSWORD
    let idBytes = utf8.encode(authId)
    let pwBytes = utf8.encode(authPW)
    let idEncoded = base64_encode(idBytes)
    let pwEncoded = base64_encode(pwBytes)

    //API call
    return await APICall(url, param, data, method, addAccessToken, idEncoded, pwEncoded)
  } catch (err) {
    err.customer = customer
    err.fsSession = fsSession
    ErrorLogger('Error executing post fullstory API', err)
  }
}

import React, { useState } from 'react'
import { useFingerprint } from '../../../../hooks/useFingerprint'
import { Grid, Paper, Stack, Typography, Box } from '@mui/material'
import { ButtonPrimary, TextField } from '../../../FormsUI'
import globalMessages from '../../../../assets/data/globalMessages.json'
import { NavLink, useNavigate, Link } from 'react-router-dom'
import { useStylesForgotPassword } from './StyleForgotPassword'
import AccountRecovery from '../../../../assets/icon/confirmation 3.png'
import Padlock from '../../../../assets/icon/padlock_1_360.png'
import ScrollToTop from '../../CheckMyOffers/ScrollToTop'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { toast } from 'react-toastify'
import { RetrieveCustomerBySSN } from '../../../Controllers/LoginForgotEmailController'
import Cookies from 'js-cookie'
import './AccountRecovery.css'
import { async } from 'q'

function AccountVerify() {
  const navigate = useNavigate()
  const classes = useStylesForgotPassword()
  const [loading, setLoading] = useState(false)
  const { visitorId } = useFingerprint()

  // *************************************************************************************************

  const [ssn, setSSN] = useState({
    part1: '',
    part2: '',
    part3: '',
  })

  const [validSSN, setValidSSN] = useState(true)
  const handleChange = (event) => {
    const { name, value } = event.target

    // Validate and format input
    if (/^\d*$/.test(value) && value.length <= 4) {
      setSSN({
        ...ssn,
        [name]: value,
      })
    }
  }

  const fieldValidation = []
  fieldValidation['part1'] = 3
  fieldValidation['part2'] = 2
  fieldValidation['part3'] = 4

  const handleBlur = (event) => {
    const { name, value } = event.target

    // Validate and format input
    if (/^\d*$/.test(value) && value.length < fieldValidation[name]) {
      setValidSSN(false)
    } else {
      setValidSSN(true)
    }
  }

  const handleKeyPress = (event, name) => {
    // Handle the Tab key
    if (event.key === 'Tab') {
      event.preventDefault()

      if (!event.shiftKey) {
        if (name === 'part1' && ssn.part1.length === 3) {
          const nextInput = document.querySelector(`input[name="part2"]`)
          nextInput && nextInput.focus()
        } else if (name === 'part2' && ssn.part2.length === 2) {
          const nextInput = document.querySelector(`input[name="part3"]`)
          nextInput && nextInput.focus()
        }
      } else {
        // Handle Shift+Tab
        if (name === 'part3') {
          const prevInput = document.querySelector(`input[name="part2"]`)
          prevInput && prevInput.focus()
        } else if (name === 'part2') {
          const prevInput = document.querySelector(`input[name="part1"]`)
          prevInput && prevInput.focus()
        }
      }
      return
    }
    // Handle Delete
    if (event.key === 'Backspace' && ssn[name] === '') {
      if (name === 'part3') {
        const prevInput = document.querySelector(`input[name="part2"]`)
        prevInput && prevInput.focus()
      } else if (name === 'part2') {
        const prevInput = document.querySelector(`input[name="part1"]`)
        prevInput && prevInput.focus()
      }
      return
    }
    //Handle Focus
    if (!event.shiftKey && event.key !== 'Backspace') {
      if (ssn[name].length === 2 && name === 'part2') {
        const nextInput = document.querySelector(`input[name="part3"]`)
        nextInput && nextInput.focus()
      } else if (ssn[name].length === 3 && name === 'part1') {
        const nextInput = document.querySelector(`input[name="part2"]`)
        nextInput && nextInput.focus()
      }
    }
  }

  const handleSubmit = async () => {
    if (ssn.part1.length === 3 && ssn.part2.length === 2 && ssn.part3.length === 4) {
      setLoading(true)
      setValidSSN(true)
      const fullSSN = `${ssn.part1}${ssn.part2}${ssn.part3}`
      let userData = await RetrieveCustomerBySSN(fullSSN, visitorId)
      setLoading(false)
      if (userData?.data?.MFAInformation && userData?.data?.result === 'success') {
        Cookies.set('email', userData?.data?.MFAInformation?.email)
        Cookies.remove('newResetPassword')
        Cookies.set('afterMFARedirect', '/recovery-success')
        navigate('/MFA_verify')
      } else if (userData?.data?.message?.includes('contact your branch')) {
        toast.error(
          'We are unable to complete your request. Please contact your branch for further assistance. Don\'t have an account? Select "Create Account"'
        )
      } else {
        toast.error(
          'Email recovery unsuccessful. Please contact support for assistance. Don\'t have an account? Select "Create Account"'
        )
      }
    } else {
      setValidSSN(false)
    }
  }

  // Concatenate the SSN parts into a single variable

  return (
    <div className={classes.forgotPasswordRoot}>
      <ScrollToTop />
      <Grid container className={classes.forgotPasswordContainer}>
        <Grid item xs={11} md={8}>
          {/* changed paper styling */}
          <Paper className={classes.forgotPasswordPaper}>
            <Link className={classes.arrowBack} to="/login">
              <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
            </Link>
            <Grid item xs={12} md={7} className={classes.mainContentArea}>
              <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                <Grid container className={classes.rotatingIcon}>
                  <img src={AccountRecovery} alt="AccountRecoveryLogo" className="spinAnimation" />
                </Grid>
                <Typography className={classes.title} data-testid="title">
                  Account Recovery
                </Typography>
                <Typography className={classes.directiveText}>
                  Enter your Social Security Number below, and we will provide you with options to verify your identity
                  and recover your email address
                </Typography>
              </Box>

              <form>
                <Grid
                  container
                  direction="column" // Change the direction to "column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.ssnInput}
                  style={{ flexWrap: 'nowrap' }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ flexWrap: 'nowrap', position: 'relative' }}
                  >
                    <Grid justifyContent="center" style={{ alignSelf: 'end' }} alignItems="center" item xs={1} sm={1}>
                      <img src={Padlock} alt="PasswordLogo" style={{ alignSelf: 'end', paddingBottom: '5px' }} />
                    </Grid>
                    <Grid item xs={4} sm={2} id="ssnTextFieldOne">
                      <TextField
                        className={classes.ssnField}
                        name="part1"
                        value={ssn.part1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => handleKeyPress(e, 'part1')}
                        design="new"
                        placeholder="XXX"
                        materialProps={{ maxLength: 3 }}
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </Grid>
                    <span variant="h5" className={classes.textFieldSpace}>
                      -
                    </span>
                    <Grid item xs={3} sm={2}>
                      <TextField
                        className={classes.ssnField}
                        design="new"
                        id="outlined-basic"
                        name="part2"
                        value={ssn.part2}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        materialProps={{ maxLength: 2 }}
                        onKeyDown={(e) => handleKeyPress(e, 'part2')}
                        placeholder="XX"
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </Grid>
                    <span variant="h5" className={classes.textFieldSpace}>
                      -
                    </span>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        className={classes.ssnField}
                        design="new"
                        id="outlined-basic"
                        name="part3"
                        value={ssn.part3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(e) => handleKeyPress(e, 'part3')}
                        placeholder="XXXX"
                        materialProps={{ maxLength: 4 }}
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!validSSN && (
                  <Grid textAlign="center">
                    <Typography className={classes.errorText}>Please enter a valid SSN.</Typography>
                  </Grid>
                )}

                <Grid className={classes.footer}>
                  <ButtonPrimary
                    disabled={ssn.part1.length !== 3 || ssn.part2.length !== 2 || ssn.part3.length !== 4 || loading}
                    onClick={() => handleSubmit()}
                    stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                  >
                    Continue
                    {loading && <AutorenewIcon className={classes.autoRenew} />}
                  </ButtonPrimary>
                  <Typography className={classes.footerText}>
                    Don&lsquo;t have an account?&nbsp;
                    <Link to="/register" className={classes.footerLink}>
                      Create Account
                    </Link>
                  </Typography>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountVerify

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import Housing from '../../../assets/images/Equalhousinglender2.png'
import Logo from '../../../assets/images/logo-yellow-white.png'
import fbIcon from '../../../assets/icon/fb.png'
import linkedInIcon from '../../../assets/icon/in.png'
import InstagramIcon from '../../../assets/icon/insta.png'
import TwitterIcon from '../../../assets/icon/twitter.png'
import phoneIcon from '../../../assets/icon/phoneIconWhite.png'
import locationIcon from '../../../assets/icon/locationIconWhite.png'
import ConsumerDialog from '../ConsumerFooterDialog/ConsumerDialog'
import SocialMediaDialog from '../ConsumerFooterDialog/SocialMediaDialog'
import './BranchLocatorFooter.css'
import globalMessages from '../../../assets/data/globalMessages.json'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function BranchLocatorFooter() {
  const [consumer, setConsumer] = useState(false)
  const [socialMedia, setSocialMedia] = useState(false)
  const [URL, setURL] = useState('')
  const isMobile = useMediaQuery('(max-width:960px)') // We set 960 pixels as threshold for desktop to mobile screen for this page
  // Consumer popup
  const handleOpenConsumer = () => {
    setConsumer(true)
  }

  // Social media redirect popup
  const handleOpensocialMedia = (customUrl) => {
    setURL(customUrl)
    setSocialMedia(true)
  }

  // Legal links
  const legalLinks = [
    {
      name: 'Community Guidelines',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-communityguidelines`,
    },
    {
      name: 'Privacy Statement',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/privacy-statement/`,
    },
    {
      name: 'Terms of Use',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-termsofuse`,
    },
    {
      name: 'Licensing and Disclosure',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-licensing&disclosures`,
    },
    {
      name: 'Texting Terms of Use',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-textingtermsofuse`,
    },
    {
      name: 'Website Accessibility',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-websiteaccessibilitystatement`,
    },
    {
      name: 'California Personal Information Collection Notice',
      link: `${process.env.REACT_APP_WEBSITE}/resources/legal/#fusion-tab-privacystatement`,
    },
    {
      name: 'CAC Terms of Use',
      link: `/cac-termsofuse`,
    },
    {
      name: 'California Residents – Do Not Sell My Personal Information',
      link: `${process.env.REACT_APP_WEBSITE}/california-consumer-privacy-act-ccpa-request/`,
    },
    {
      name: 'Sitemap',
      link: `https://${process.env.REACT_APP_HOST_NAME}/sitemap.xml`,
    },
  ]

  // Links shown in the branch locator footer section
  const footers = [
    {
      title: 'Personal Loans',
      description: [
        {
          name: 'Unexpected Expenses',
          link: `${process.env.REACT_APP_WEBSITE}/personal-loans/unexpected-expenses/`,
        },
        {
          name: 'Debt Consolidation Loans',
          link: `${process.env.REACT_APP_WEBSITE}/personal-loans/debt-consolidation-loans/`,
        },
        {
          name: 'Home Improvement Loans',
          link: `${process.env.REACT_APP_WEBSITE}/personal-loans/home-improvement-loans/`,
        },
        {
          name: 'Wedding Loans',
          link: `${process.env.REACT_APP_WEBSITE}/personal-loans/wedding-loans/`,
        },
        {
          name: 'Vacation Loans',
          link: `${process.env.REACT_APP_WEBSITE}/personal-loans/vacation-loans/`,
        },
      ],
    },
    {
      title: 'Car Loans',
      description: [
        {
          name: 'Car Refinancing Loans',
          link: `${process.env.REACT_APP_WEBSITE}/car-loans/auto-refinance/`,
        },
        {
          name: 'Finance Car Loans',
          link: `${process.env.REACT_APP_WEBSITE}/car-loans/new-car-loan/`,
        },
        {
          name: 'Finance Used Car Loans',
          link: `${process.env.REACT_APP_WEBSITE}/car-loans/used-car-loan/`,
        },
        {
          name: '',
          link: `#`,
        },
      ],
    },
    {
      title: 'Home Loans',
      description: [
        {
          name: 'Mortgage Loans',
          link: `${process.env.REACT_APP_WEBSITE}/home-loans/mortgage-loans/`,
        },
        {
          name: 'Home Refinance',
          link: `${process.env.REACT_APP_WEBSITE}/home-loans/home-refinance/`,
        },
        {
          name: 'Meet our Loan Officers',
          link: `${process.env.REACT_APP_WEBSITE}/home-loans/meet-our-loan-officers/`,
        },
      ],
    },
    {
      title: 'Resources',
      description: [
        {
          name: 'How to apply for a Personal Loan',
          link: `${process.env.REACT_APP_WEBSITE}/resources/how-to-apply/`,
        },
        {
          name: 'FAQs',
          link: `${process.env.REACT_APP_WEBSITE}/resources/faq/`,
        },
        {
          name: 'Blog',
          link: `${process.env.REACT_APP_WEBSITE}/blog/`,
        },
        {
          name: 'Mariner States',
          link: `${process.env.REACT_APP_WEBSITE}/state/`,
        },
        {
          name: 'Loan Calculator',
          link: `${process.env.REACT_APP_WEBSITE}/loan-calculator/`,
        },
      ],
    },
    {
      title: 'Quick Links',
      description: [
        {
          name: 'Careers',
          link: `${process.env.REACT_APP_WEBSITE}/why-mariner-finance/careers/`,
        },
        {
          name: 'Resume Application',
          link: `${process.env.REACT_APP_WEBSITE}/customer-support/`,
        },
        {
          name: 'Customer Support',
          link: `${process.env.REACT_APP_WEBSITE}/customer-support/`,
        },
        {
          name: 'Branch Locator',
          link: 'https://locations.marinerfinance.com/',
        },
      ],
    },
    {
      title: 'Legal',
      skipMobileView: true,
      description: legalLinks,
    },
  ]

  // createGroup function used to seperate the footer links groups into set of 2 indivual groups for alignment purpose
  function createGroups(arr, numGroups) {
    const perGroup = Math.ceil(arr.length / numGroups)
    return new Array(numGroups).fill('').map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
  }
  const groupedFooter = createGroups(footers, 6)

  // SocialMediaIconsTray has Facebook, LinkedIn, Twitter and Instagram logos and relate3d links
  const SocialMediaIconsTray = () => {
    return (
      <>
        <IconButton
          id="facebookIcon"
          className="socialButtonStyle"
          data-testid="facebookButton"
          aria-label="facebookIcon"
          onClick={() => {
            handleOpensocialMedia('https://www.facebook.com/MarinerFinance/')
          }}
        >
          <img src={fbIcon} className="smIcons smImg" />
        </IconButton>
        <IconButton
          id="linkedIcon"
          className="socialButtonStyle"
          data-testid="linkedInButton"
          aria-label="linkedIcon"
          onClick={() => {
            handleOpensocialMedia('https://www.linkedin.com/company/mariner-finance/')
          }}
        >
          <img src={linkedInIcon} className="smIcons smImg" />
        </IconButton>
        <IconButton
          id="twitterIcon"
          className="socialButtonStyle"
          data-testid="twitterButton"
          aria-label="twitterIcon"
          onClick={() => {
            handleOpensocialMedia('https://twitter.com/MarinerFinance')
          }}
        >
          <img src={TwitterIcon} className="smIcons smImg" />
        </IconButton>
        <IconButton
          className="socialButtonStyle"
          id="instagramIcon"
          data-testid="instagramButton"
          aria-label="instagramIcon"
          onClick={() => {
            handleOpensocialMedia('https://www.instagram.com/marinerfinancellc/')
          }}
        >
          <img src={InstagramIcon} className="smIcons smImg" />
        </IconButton>
      </>
    )
  }

  // Footer design for mobile screens, which has accordions. It loads when screen size goes less than 960

  const MobileFooter = () => {
    return (
      <footer className="footerBranchLocator">
        <Grid className="branchLocatorFooterWrap">
          <section className="branch-Locator-Footer">
            <Grid container>
              <Grid className="footerGridStyle" item xs={12} sm={12} container spacing={0}>
                <Grid item xs={8}>
                  <Link aria-label="Mariner Finance Home" className="footerLogoimageWrap" to="#">
                    {Logo ? (
                      <img width="157px" height="62px" data-testid="footerLogoImage" src={Logo} alt="MF-Logo" />
                    ) : (
                      ''
                    )}
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link aria-label="Equal Housing Lender" className="footerLogoimageWrap" to="#">
                    {Housing ? (
                      <img
                        width="69px"
                        height="75px"
                        data-testid="housingImage"
                        src={Housing}
                        className="imgHousing"
                        alt=""
                      />
                    ) : (
                      ''
                    )}
                  </Link>
                </Grid>
              </Grid>
              <Grid className="footerGridStyle" item xs={12} sm={12} container spacing={2}>
                <ul className="branchfooterUl fullWidth">
                  <Typography variant="h6" className="footerLinkstitle padListStyle" gutterBottom>
                    Legal and Privacy
                  </Typography>
                  {legalLinks.map((legalLink, legalIndex) => (
                    <li key={`${legalLink.name}-${legalIndex}`} className="branchfooterLi padListStyle">
                      <a target={legalLink.getTarget} href={legalLink.link} className="footerHrefTag padListStyle">
                        {legalLink.name}
                      </a>
                    </li>
                  ))}
                  <div className="lineDivList" />
                </ul>
              </Grid>
              <Grid className="footerGridStyle" item xs={12} sm={12} container spacing={2}>
                <Typography variant="h6" className="footerLinkstitle fullWidth padListStyle" gutterBottom>
                  California Residents
                </Typography>
                <Typography className="whiteText footerHrefTag">
                  Loans made or arranged pursuant to a California Financing Law license.
                </Typography>
                <div className="lineDivList" />
              </Grid>
              <Grid className="footerGridStyle" item xs={12} sm={12} container spacing={2}>
                <Typography variant="h6" className="footerLinkstitle fullWidth padListStyle" gutterBottom>
                  VA Residents
                </Typography>
                <Typography className="whiteText footerHrefTag">
                  Mariner Finance of Virginia. LLC, Licensed by the Virginia State Corporation Commission, Consumer
                  Consumer Finance Company License No.CFI 114.
                </Typography>
                <div className="lineDivList" />
              </Grid>
              <Grid item xs={12} sm={12} container spacing={0}>
                {groupedFooter.map((nested, nestedIndex) =>
                  nested.map((element, idx) => {
                    if (element?.skipMobileView !== true) {
                      return (
                        <Grid item xs={12} sm={12} key={`branch-locator-footer2-main-${nestedIndex}`}>
                          <Accordion className="accordianBackground" key={`${element.title}-${idx}`}>
                            <AccordionSummary
                              key={`${element.title}-${idx}`}
                              expandIcon={<ExpandMoreIcon className="expandIconStyle" size="large" />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="accordianSummeryStyle"
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Typography variant="h6" className="footerLinkstitle whiteText">
                                {element.title}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordionDetailStyle">
                              {element?.description?.map((item, index) => (
                                <ul className="branchfooterUl" key={`branch-locator-footer2-${index}`}>
                                  <li key={`${item.name}-${index}`} className="branchfooterLi accordianLinkStyle">
                                    <a target={item.getTarget} href={item.link} className="footerHrefTag">
                                      {item.name}
                                    </a>
                                  </li>
                                </ul>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                          <div className="lineDiv" />
                        </Grid>
                      )
                    }
                  })
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={0}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                className="contactTrayStyle"
              >
                <Grid item xs={1} sm={1} className="locationIconGridStyle">
                  <img src={locationIcon} width="35px" height="35px" className="whiteText svg_icons" />
                </Grid>
                <Grid item xs={10} className="whiteText locationTextStyle">
                  <Typography className="locationText">8211 Town Center Drive Nottingham, MD 21236, USA</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                spacing={0}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={1} sm={1} className="locationIconGridStyle">
                  <img src={phoneIcon} width="25px" height="25px" className="whiteText svg_icons" />
                </Grid>
                <Grid item xs={10} className="whiteText locationTextStyle">
                  <a href="tel:+8773102373" className="hrefTag whiteText">
                    <Typography className="locationText">877-310-2373</Typography>
                  </a>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
                className="footerNameStyle"
              >
                <Grid item xs={12} sm={8} className="alignAddressDiv">
                  <p data-testid="footerAddressText" className="centerAlignAddress whiteText">
                    Mariner Finance, LLC, NMLS No.166564{' '}
                    <span data-testid="consumerAccessOnclick" className="alignAddressSpan" onClick={handleOpenConsumer}>
                      (www.nmlsconsumeraccess.com)
                    </span>{' '}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} container spacing={0} justifyContent="center" alignItems="center">
                <Grid item lg={6} md={6} sm={6} container justifyContent="center" alignItems="center">
                  <SocialMediaIconsTray />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                container
                spacing={0}
                className="footerRightsTextStyle"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={8} md={8} sm={10} xs={12}>
                  <p data-testid="copyrightText" className="copyrightSpan">
                    &copy; {new Date().getFullYear()} Mariner Finance All rights reserved.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </section>
        </Grid>
      </footer>
    )
  }

  // Footer design for desktop screens. It loads when screen size goes more than 960

  const DesktopFooter = () => {
    return (
      <footer className="footerBranchLocator">
        <Grid container className="branchLocatorFooterWrap">
          <section className="branch-Locator-Footer">
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={4} justifyContent="space-evenly">
              {groupedFooter.map((nested, nestedIndex) => (
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  className="verticalListStyle"
                  key={`branch-locator-footer2-main-${nestedIndex}`}
                >
                  {nested.map((element, idx) => (
                    <div key={`${element.title}-${idx}`} className="divFooterLinkTitle">
                      <Typography variant="h6" className="footerLinkstitle" gutterBottom>
                        {element.title}
                      </Typography>
                      {element?.description?.map((item, index) => (
                        <ul className="branchfooterUl" key={`branch-locator-footer2-${index}`}>
                          <li key={`${item.name}-${index}`} className="branchfooterLi">
                            <a target={item.getTarget} href={item.link} className="footerHrefTag">
                              {item.name}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </div>
                  ))}
                </Grid>
              ))}
              <Grid className="footerGridStyle footerListSecondPart" item xs={12} sm={12} container spacing={0}>
                <Grid item xs={4} sm={4} md={4}>
                  <div className="divFooterLinkTitle footerLinkRightMargin">
                    <Typography variant="h6" className="footerLinkstitle" gutterBottom>
                      California Residents
                      <br />
                    </Typography>
                    <Typography className="footerHrefTag">
                      Loans made or arranged pursuant to a California Financing Law License
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <div className="divFooterLinkTitle">
                    <Typography variant="h6" className="footerLinkstitle" gutterBottom>
                      VA Residents
                    </Typography>
                    <Typography className="footerHrefTag">
                      Mariner Finance of Virginia. LLC, Licensed by the Virginia State Corporation Commission, Consumer
                      Finance Company License No.CFI-114.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </section>
          <Box sx={{ flexGrow: 1, justifyContent: 'space-evenly' }}>
            <Grid container justifyContent="center" direction="row" alignItems="center" className="footerLogoLinksWrap">
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Link aria-label="Mariner Finance Home" className="footerLogoimageWrap" to="#">
                  {Logo ? (
                    <img width="157px" height="62px" data-testid="footerLogoImage" src={Logo} alt="MF-Logo" />
                  ) : (
                    ''
                  )}
                </Link>
              </Grid>
              <Grid item xs={7} sm={7} md={9} lg={8}>
                <div className="row">
                  <div>
                    <p data-testid="footerAddressText" className="centerAlignAddress whiteText">
                      Mariner Finance, LLC, NMLS No.166564{' '}
                      <span
                        data-testid="consumerAccessOnclick"
                        className="alignAddressSpan"
                        onClick={handleOpenConsumer}
                      >
                        (www.nmlsconsumeraccess.com)
                      </span>{' '}
                    </p>
                    <p className="centerAlignAddress whiteText">8211 Town Center Drive, Nottingham, MD 21236.</p>
                    <p className="centerAlignAddress whiteText">
                      Customer Service
                      <a href="tel:+8773102373" className="hrefTag whiteText">
                        &nbsp; 877-310-2373
                      </a>
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={2}>
                <Link aria-label="Equal Housing Lender" className="footerLogoimageWrap" to="#">
                  {Housing ? (
                    <img width="60px" data-testid="housingImage" src={Housing} className="imgHousing" alt="" />
                  ) : (
                    ''
                  )}
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Grid container className="hrLine">
            <Grid item lg={6} md={6} sm={6}>
              <span data-testid="copyrightText" className="copyrightSpan">
                &copy; {new Date().getFullYear()} Mariner Finance All rights reserved.
              </span>
            </Grid>
            <Grid item lg={6} md={6} sm={6} className="socialMediaIconTrayFooter">
              <SocialMediaIconsTray />
            </Grid>
          </Grid>
        </Grid>
      </footer>
    )
  }

  //View Part
  return (
    <div data-testid="branch_locater_footer_component" className="mainDivBranchLocatorFooter">
      <Grid className="branchLocatorFooterWrapDisclosure">
        <section className="paragraph">
          <p>
            <small data-testid="personalTexts">{globalMessages.We_Offer_personal_loans}</small>
          </p>
          <p>
            <small data-testid="helpTexts">{globalMessages.Refinance_Exist_loan}</small>
          </p>
          <p>
            <small data-testid="processTexts">{globalMessages.Credit_Inquiry}</small>
          </p>
          <p>
            <small data-testid="daysGuarantee">{globalMessages.Fifteen_Days_Guarantee}</small>
          </p>
          <p>
            <small>
              ***The reviews and/or testimonials presented on the Mariner Finance website are voluntarily provided
              without incentive, financial or otherwise. Testimonials presented with individual{'’'}s consent and may
              have been edited for clarity and consistency purposes. All reviews are available for review via the{' '}
              <a className="footerLinks" href={`${process.env.REACT_APP_WEBSITE}/testimonials/`}>
                “Read all reviews”
              </a>
              link and are representative of an individual
              {'’'}s experience(s). Some reviews are highlighted more prominently and are filtered to demonstrate
              opinions of satisfied customers. Mariner welcomes and encourages all feedback and reviews. You can leave a
              review by visiting an applicable Google My Business page and/or{' '}
              <a
                className="footerLinks"
                href="https://www.feefo.com/en_US/reviews/mariner-finance?displayFeedbackType=SERVICE&timeFrame=YEAR"
              >
                FEEFO
              </a>{' '}
              and/or{' '}
              <a className="footerLinks" href="https://www.trustpilot.com/review/marinerfinance.com">
                Trustpilot
              </a>
              .
            </small>
          </p>
        </section>
      </Grid>
      {isMobile ? <MobileFooter /> : <DesktopFooter />}
      <ConsumerDialog consumer={consumer} onChange={setConsumer} />
      <SocialMediaDialog URL={URL} socialMedia={socialMedia} onChange={setSocialMedia} />
    </div>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subOption {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: 'Muli', sans-serif !important;
  color: #0f4eb3 !important;
  padding: 7px 0px !important;
}

#mui-component-select-expiryMonth span {
  color: currentColor !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormsUI/Select/SelectBox.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,0BAA0B;EAC1B,wBAAwB;EACxB,qBAAqB;EACrB,0CAA0C;EAC1C,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".subOption {\n  overflow-x: auto;\n  white-space: pre-wrap;\n  white-space: -moz-pre-wrap;\n  white-space: -o-pre-wrap;\n  word-wrap: break-word;\n  font-family: 'Muli', sans-serif !important;\n  color: #0f4eb3 !important;\n  padding: 7px 0px !important;\n}\n\n#mui-component-select-expiryMonth span {\n  color: currentColor !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import ErrorLogger from '../lib/ErrorLogger'

/***** Get Account overview details *****/
export default async function MoneySkillController() {
  try {
    let url = 'getMoneySkillLink'
    let param = ''
    let data = {}
    let method = 'POST'
    let addAccessToken = true

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_getMoneySkillLink_API, error)
  }
}

import React, { useState } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { ButtonPrimary } from '../../../FormsUI'
import globalMessages from '../../../../assets/data/globalMessages.json'
import { useStylesForgotPassword } from './StyleForgotPassword'
import successLogo from '../../../../assets/icon/confirmation 3-2.png'
import ScrollToTop from '../../CheckMyOffers/ScrollToTop'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { green } from '@mui/material/colors'
import Cookies from 'js-cookie'
import ForgotPasswordMessages from '../../../../assets/data/ForgetPasswordMessages.json'

function RecoverySuccess() {
  const navigate = useNavigate()
  const classes = useStylesForgotPassword()
  //Yup validations for all the input fields

  let email = Cookies.get('email')
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ScrollToTop />
      <Grid
        item
        xs={11}
        sm={10}
        md={8}
        lg={6}
        xl={6}
        justifyContent="center"
        alignItems="center"
        container
        style={{ width: '100%' }}
      >
        <Paper className={classes.paperStyle2} elevation={0}>
          <Grid container className={classes.passwordLogo}>
            <img src={successLogo} alt="PasswordLogo" />
          </Grid>
          <Typography className={classes.title}>{ForgotPasswordMessages.AccountRecoverSuccess}</Typography>
          <Typography sx={{ textAlign: 'center' }} className={classes.textPartPadding}>
            {ForgotPasswordMessages.UserAccountEmail}
          </Typography>
          <form>
            <Stack direction="row" className={classes.stackSection} spacing={2} justifyContent={'center'}>
              <Avatar alt={email ?? userAvatar} sx={{ bgcolor: green[500] }} src="../../../../assets/icon/amOne.png" />
              <Typography className={classes.mailIdDisplay}>{email ?? ''}</Typography>
            </Stack>
            <Grid item xs={12} className={classes.sendLinkButton}>
              <ButtonPrimary
                onClick={() => {
                  navigate('/login')
                }}
                stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
              >
                Sign In
              </ButtonPrimary>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </div>
  )
}

export default RecoverySuccess

/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Paper,
  Box,
} from '@mui/material'

import { ButtonPrimary, Popup } from '../../FormsUI'
import { useStylesMFA } from './Style'
import PropTypes from 'prop-types'
import PhoneNumberPopUp from './PhoneNumberPopUp'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import { useMutation } from 'react-query'
import { SendLoginPassCode } from '../../Controllers/MFAController'
import Cookies from 'js-cookie'
import confirmation3 from '../../../assets/icon/confirmation3.png'
import AutorenewIcon from '@mui/icons-material/Autorenew'
const Buffer = require('buffer/').Buffer

const MFASelection = ({ securityQuestionsSaved, phoneNumberList = [], mfaDetails, disableSecurityQuestions }) => {
  const classes = useStylesMFA()
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [selection, setSelection] = useState()
  const { mutateAsync, isLoading } = useMutation(SendLoginPassCode)
  const SECURITY_QUESTIONS = 'security questions'
  const PHONE = 'phone'
  const DISABLE_CONDITION = value === PHONE && selection !== SECURITY_QUESTIONS && Boolean(selection)

  const buttonDisable = () => {
    if (!value && !selection) {
      return true
    } else if ((value === SECURITY_QUESTIONS && selection === SECURITY_QUESTIONS) || DISABLE_CONDITION) {
      return false
    } else {
      return true
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handlePopUp = () => {
    setPopUp(true)
  }
  const handlePopUpClose = () => {
    setPopUp(false)
  }

  const handleClick = async () => {
    Cookies.set('getPhoneFromUser', phoneNumberList?.length > 0 ? false : true)
    if (selection !== SECURITY_QUESTIONS) {
      let encodedPhoneList = mfaDetails.mfaDetails.encoded_PhoneNumbers
      let phoneNumber = ''

      encodedPhoneList.forEach((number, i) => {
        let last4 = ''
        if (selection) {
          last4 = selection.substring(6)
        }
        let bufferObj = Buffer.from(number, 'base64')
        let decodedString = bufferObj.toString('utf8')

        if (last4 === decodedString.substring(6)) {
          phoneNumber = decodedString
        }
      })

      if (phoneNumber === '') {
        toast.error('You must select a phone number.')
      } else {
        const passCodeResponse = await mutateAsync(phoneNumber)

        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line
          console.log(passCodeResponse) //Left this console log intentionally for QA
        }

        passCodeResponse?.data?.passcodeInTextMessage
          ? navigate('/MFA-OTP', { state: { phoneNumber: phoneNumber, mfaQueries: mfaDetails, currentFlow: true } })
          : toast.error(passCodeResponse.data?.Message) //Navigate to OTP page or else show error.
      }
    } else if (selection === SECURITY_QUESTIONS && securityQuestionsSaved) {
      navigate('/MFA-SecurityQuestions', { state: { mfaSecurityQuestions: mfaDetails, currentFlow: true } })
    } else {
      selection === SECURITY_QUESTIONS &&
        !securityQuestionsSaved &&
        navigate('/mfa-kbaQuestions', { state: { mfaSecurityQuestions: mfaDetails, currentFlow: true } })
    }
  }

  const securityCode = (
    <div className={classes.securityCodeText}>
      <b>Select a phone number to receive an authentication PIN via text :</b>
      <br />
      {phoneNumberList?.length === 1 ? (
        <span>{`Get a code on (***) ***-${phoneNumberList[0]?.number.substr(-4)}`}</span>
      ) : DISABLE_CONDITION ? (
        <span>To phone number : {`(***) ***-${selection?.substr(-4)}`}</span>
      ) : (
        <span>Get a code on your preferred phone number</span>
      )}
    </div>
  )
  const securityQuestions = (
    <div className={classes.securityQuestions}>
      <b>Security Questions :</b>
      <br />
      Answer Identity Verification Questions.{' '}
    </div>
  )

  return (
    <div className={isLoading ? classes.loadingOn : classes.loadingOff}>
      <ScrollToTop />
      <Grid data-testid="mfa_Selection">
        <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
          <Paper className={classes.forgotPasswordPaper}>
            <Grid item xs={12} md={10} className={classes.mainContentArea}>
              <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
                <Grid container className={classes.rotatingIcon}>
                  <img src={confirmation3} alt="verify Identity" className="spinAnimation" />
                </Grid>
                <Typography style={{ textAlign: 'center' }} className={classes.title} data-testid="title">
                  Please complete Multi Factor Authentication
                </Typography>

                <Typography className={classes.directiveText} data-testid="title1">
                  {phoneNumberList?.length
                    ? `For your security, please select a method to verify your identity.`
                    : `For your security, please select security question verification to complete your login.`}
                  {/* Phone Number Array Length is 0, then only security question */}
                </Typography>
              </Box>

              <Grid container direction="column" display="flex" alignItems="center">
                <Box>
                  <RadioGroup
                    id="textAndCallss"
                    aria-label="method"
                    name="method"
                    row={false}
                    value={value}
                    onChange={handleChange}
                  >
                    {phoneNumberList?.length > 0 ? (
                      <FormControlLabel
                        id="FormControlLabel"
                        className={`${classes.radioGroup} ${classes.radioSpacing}`}
                        value={PHONE}
                        control={
                          <Radio
                            className={classes.customRadio}
                            color="primary"
                            data-testid="phoneSelection"
                            onClick={
                              phoneNumberList.length > 1
                                ? () => handlePopUp()
                                : () => setSelection(`${phoneNumberList[0].number}`)
                            }
                          />
                        }
                        label={securityCode}
                      />
                    ) : null}
                    <FormControlLabel
                      id="FormControlLabel"
                      className={classes.radioGroup}
                      value={SECURITY_QUESTIONS}
                      control={
                        <Radio
                          className={classes.customRadio}
                          color="primary"
                          disabled={disableSecurityQuestions}
                          data-testid="questionSelection"
                          onClick={() => setSelection(SECURITY_QUESTIONS)}
                        />
                      }
                      label={securityQuestions}
                    />
                  </RadioGroup>
                </Box>
              </Grid>

              <Box className={classes.disclosure}>
                <ButtonPrimary
                  data-testid="next_Step"
                  stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"205px","height":"42px"}'
                  disabled={buttonDisable() || isLoading}
                  onClick={handleClick}
                >
                  {' '}
                  {value ? (value === PHONE ? 'Send PIN Code' : 'Proceed to Questions') : 'Next'}
                  <AutorenewIcon className="rotatingIcon" style={{ display: isLoading ? 'block' : 'none' }} />
                </ButtonPrimary>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Popup maxWidth="sm" popupFlag={popUp} closePopup={handlePopUpClose} title="Select your preferred Phone Number">
        <PhoneNumberPopUp phoneNumberList={phoneNumberList} setSelection={setSelection} />
      </Popup>
    </div>
  )
}

MFASelection.propTypes = {
  securityQuestionsSaved: PropTypes.bool,
  phoneNumberList: PropTypes.array,
  mfaDetails: PropTypes.object,
  disableSecurityQuestions: PropTypes.bool,
}

export default MFASelection

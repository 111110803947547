/*#################################################################################################################

File Name           :    SocialSecurityNumber/index.js
Component Name      :    SocialSecurityNumber
Functionality       :    To use this component to validate and get the SSN in the correct format from the user.

#################################################################################################################*/
import { FormLabel, Grid, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { makeStyles } from '@mui/styles'
import dynamicStyles from '../OutlineStyles'

const SSNWrapper = ({
  name,
  label,
  error,
  required,
  value,
  onChange,
  helperText,
  design,
  setError,
  setHelperText,
  placeholder,
  mask,
  ...otherProps
}) => {
  const [unmaskedval, setUnMaskedVal] = useState('')

  const useStyles = makeStyles((theme) => ({
    textFieldWrapper: {
      width: 'inherit',
      padding: '0 10px',
    },
    formLabelStyle: {
      paddingLeft: '2px',
      paddingBottom: '5px',
    },
  }))

  const borderClasses = dynamicStyles()
  const classes = useStyles()

  const configTextField = {
    classes: {
      root: borderClasses.dynamic,
    },
  }

  const handleChange = (event) => {
    setUnMaskedVal(event.target.value.replace(/-/g, '').replace(/ /g, '') || '')

    if (onChange) {
      onChange(event)
    }
  }

  return (
    <Grid className={classes.textFieldWrapper}>
      <FormControl fullWidth={true}>
        <FormLabel>{label}</FormLabel>
        <InputMask
          fullWidth={true}
          mask={mask ? mask : '999-99-9999'}
          value={value}
          name={name}
          onChange={handleChange}
          disabled={false}
          maskChar=""
          {...otherProps}
        >
          {() => (
            <TextField
              // label={label}
              name={name}
              {...configTextField}
              error={error}
              placeholder={placeholder}
              helperText={helperText}
              variant={design === 'new' ? 'outlined' : 'standard'}
              inputProps={{ 'data-testid': 'ssn', unmaskedval: unmaskedval }}
            />
          )}
        </InputMask>
      </FormControl>
    </Grid>
  )
}

SSNWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  setError: PropTypes.string,
  setHelperText: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  design: PropTypes.string,
}

export default SSNWrapper

/*#################################################################################################################

File Name           :    Password/index.js
Component Name      :    Password
Functionality       :    To use this component to validate and get the Password with hide and show option.

#################################################################################################################*/
import { FormLabel, IconButton, InputAdornment, TextField, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { makeStyles } from '@mui/styles'
import '../iframe.css'
import dynamicStyles from '../OutlineStyles'
import { classNames } from 'classnames'

//Initializing component
const PasswordWrapper = ({ name, label, design, materialProps, id, labelStyle, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState({
    password: '',
  })
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const useStyles = makeStyles((theme) => ({
    textFieldWrapper: {
      width: 'inherit',
      padding: '0 10px',
    },
    formLabelStyle: {
      paddingLeft: '2px',
      paddingBottom: '5px',
    },
  }))

  const classes = useStyles()
  const borderClasses = dynamicStyles()

  const configTextField = {
    classes: {
      root: borderClasses.dynamic,
    },
  }

  return (
    <>
      {design === 'new' ? (
        <Grid style={{ padding: '2px 0' }}>
          <FormLabel className={labelStyle}> {label} </FormLabel>
        </Grid>
      ) : (
        ''
      )}
      <TextField
        className="globelPassword"
        fullWidth={true}
        label={design === 'new' ? '' : label}
        variant={design === 'new' ? 'outlined' : 'standard'}
        id={id}
        name={name}
        {...otherProps}
        {...configTextField}
        type={!showPassword ? 'text' : 'password'}
        inputProps={materialProps}
        InputProps={{
          'data-testid': 'passProps',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                data-testid="passButton"
                className="rem1FSize"
              >
                {design === 'new' ? (
                  !showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )
                ) : !showPassword ? (
                  'Hide'
                ) : (
                  'Show'
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default PasswordWrapper

PasswordWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  materialProps: PropTypes.object,
  id: PropTypes.string,
  design: PropTypes.string,
  labelStyle: PropTypes.string,
}

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSession } from '../../../contexts/SessionContext'
import { useStylesForgotPassword } from './Style'
// import { useStylesMFA } from './MFAStyle.js'
import { toast } from 'react-toastify'
import { useNavigate, Link, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import globalMessages from '../../../assets/data/globalMessages.json'
import AccountRecovery from '../../../assets/icon/confirmation 3.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import {
  TextField,
  Typography,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { ButtonPrimary, OtpField, EmailTextField } from '../../FormsUI'
import ScrollToTop from '../ScrollToTop'
// import './MultiFactorAuthentication.css'
import ForgotPasswordMessages from '../../../assets/data/ForgetPasswordMessages.json'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import PasswordLogo from '../../../assets/icon/confirmation 2.png'
import {
  requestOTP,
  validateOTP,
  validateMagicLink,
  validateRecoveryAnswer,
} from '../../Controllers/PasswordRecoveryController'
import CircularProgress from '@mui/material/CircularProgress'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Cookies from 'js-cookie'
import Messages from '../../../assets/data/globalMessages.json'

function ForgotPasswordOTPPasscode() {
  const classes = useStylesForgotPassword()
  const { state: session, dispatch } = useSession() // Use session state and dispatch from context
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const selectedPhoneNumber = session?.selectedPhoneNumber

  // Function to format phone number, extracting the last 4 digits
  const formatPhoneNumber = (phone) => {
    return phone ? `(***) ***-${phone.slice(-4)}.` : ''
  }

  // Sync session object and local state to avoid undefined errors propogate events from child components up into parent
  function setStepBasedOnSession(session, navigate, setStep) {
    let step = 1
    if (session.hasOwnProperty('currentStep')) {
      step = session.currentStep
    } else if (
      session.hasOwnProperty('completedSteps') &&
      session.completedSteps.includes('request-password-reset') &&
      session.completedSteps.includes('validate-magic-link')
    ) {
      step = 2
    } else if (session.hasOwnProperty('otp') && session.otp !== null) {
      step = 3
    } else if (session.hasOwnProperty('selectedMFA') && session.selectedMFA === 'security_questions') {
      step = 4
    } else if (session.hasOwnProperty('isLocked') && session.isLocked === true) {
      navigate('/login')
      return
    }
    setStep(step)
  }

  useEffect(() => {
    setStepBasedOnSession(session, navigate, setStep)
  }, [session, navigate, setStep])

  useEffect(() => {
    if (session.hasOwnProperty('isLocked') && session.isLocked === true) {
      setTimeout(() => navigate('/login'), 200)
    }
  }, [session, navigate])

  const getCardConfig = (step) => {
    switch (step) {
      case 1:
        return {
          title: 'Verify your identity',
          subtitle: 'Verifying session token',
          icon: <img src={PasswordLogo} alt="PasswordLogo" className="spinAnimation" />,
          component: <ValidatingTokenComponent step={step} setStep={setStep} session={session} dispatch={dispatch} />,
        }
      case 2:
        return {
          title: 'Verify your identity',
          subtitle: 'For your security, please select a method to verify your identity',
          icon: <img src={PasswordLogo} alt="PasswordLogo" className="spinAnimation" />,
          component: <SelectMethodComponent step={step} setStep={setStep} session={session} dispatch={dispatch} />,
        }

      case 3:
        return {
          title: 'Verify your identity',
          subtitle: `Enter the code sent to your number: ${formatPhoneNumber(selectedPhoneNumber)}`,
          disclosureText: '',
          icon: <img src={PasswordLogo} alt="PasswordLogo" className="spinAnimation" />,
          component: <OTPInputComponent step={step} setStep={setStep} session={session} dispatch={dispatch} />,
        }
      case 4:
        return {
          title: 'Verify Account',
          subtitle: '',
          icon: <img src={PasswordLogo} alt="PasswordLogo" className="spinAnimation" />,
          component: (
            <MFASecurityQuestionsComponent
              step={step}
              setStep={setStep}
              loading={loading}
              setLoading={setLoading}
              session={session}
              dispatch={dispatch}
            />
          ),
        }
      default:
        return {
          title: 'Unknown Step (Possibly "unauthorized" or "invalid") page and help)',
          subtitle: 'Unknown Step Description',
          icon: null,
          component: null,
        }
    }
  }

  const cardConfig = useMemo(() => getCardConfig(step), [step])

  return (
    <div className={classes.forgotPasswordRoot}>
      <ScrollToTop />
      <Grid item container xs={11} md={8} className={classes.forgotPasswordContainer}>
        <Paper className={classes.forgotPasswordPaper}>
          <Grid item xs={12} md={10} className={classes.mainContentArea}>
            <Box display="flex" flexDirection="column" gap={2} paddingBottom={0} alignItems="center">
              <Grid container className={classes.rotatingIcon}>
                {cardConfig.icon || <img src={PasswordLogo} alt="PasswordLogo" className="spinAnimation" />}
              </Grid>
              <Typography className={classes.title}>{cardConfig.title}</Typography>
              <Typography className={classes.directiveText}>{cardConfig.subtitle}</Typography>
              <Typography className={classes.footerText}>{cardConfig.disclosureText}</Typography>
            </Box>

            {cardConfig.component}
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}
function ValidatingTokenComponent(props) {
  const { session, setStep, dispatch } = props
  const classes = useStylesForgotPassword()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await validateMagicLink(session.sessionID)
        if (response && response?.data?.phoneNumbers && response?.data?.phoneNumbers.length > 0) {
          setLoading(false)
          dispatch({ type: 'SET_SESSION', payload: response }) // Set session data
          dispatch({ type: 'SET_COMPLETED_STEPS', payload: ['request-password-reset', 'validate-magic-link'] }) // Set completed steps
          dispatch({ type: 'SET_CURRENT_STEP', payload: 2 }) // Set current step to 2
          dispatch({ type: 'SET_MFA_OPTIONS', payload: response.data })
          if (response?.data?.isLocked) {
            dispatch({ type: 'SET_IS_LOCKED' })
          }
          setStep(2)
        } else {
          throw new Error(response?.message || globalMessages.Unable_to_process_your_request)
        }
      } catch (error) {
        let errorMessage = error?.response?.data?.error?.message || error?.message || error
        toast.error(errorMessage)
        return dispatch({ type: 'SET_IS_LOCKED', payload: true })
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [session.sessionID, dispatch, setStep])
  return (
    <div className={classes.forgotPasswordRoot} data-testid="passcode-verification-container">
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        {loading ? <CircularProgress /> : null}
        {loading ? (
          <Typography>Please wait while we process your request...</Typography>
        ) : error ? (
          <Typography>{error}</Typography> // Display error message
        ) : null}

        <Typography className={classes.directiveText}>
          Having trouble?{' '}
          <span className="blueColorLink" style={{ cursor: 'pointer' }}>
            Contact us
          </span>
        </Typography>
      </Box>
    </div>
  )
}

function SelectMethodComponent() {
  const classes = useStylesForgotPassword()
  const { state: session, dispatch } = useSession() // Destructure state and dispatch from useSession
  const [selectedMethod, setSelectedMethod] = useState('')
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('') // corrected from setSelectedPhone to setSelectedPhoneNumber for consistency
  const [isPhoneDialogOpen, setPhoneDialogOpen] = useState(false) // dialog control for phone option selected
  const [hasMultiplePhoneNumbers, setHasMultiplePhoneNumbers] = useState(false)
  const [displaySecurityQuestionOption, setDisplaySecurityQuestionOption] = useState(false) // dialog control for security question option selected
  const [pageReady, setPageReady] = useState(false)
  const mfaOptions = session?.mfaOptions || {}
  const phoneNumbers = session?.mfaOptions?.phoneNumbers || []
  const securityQuestions = session?.mfaOptions?.securityQuestions || []
  const [loading, setLoading] = useState(false)

  /**
   * On component mount and when session changes, determines which options to show the user based on their data.
   */
  useEffect(() => {
    determinePageVariations()
  }, [session])

  function determinePageVariations() {
    const hasSecurityQuestions =
      session?.mfaOptions?.securityQuestionsSaved || (Array.isArray(securityQuestions) && securityQuestions.length > 0)
    setDisplaySecurityQuestionOption(hasSecurityQuestions)

    const multiplePhoneNumbers = Array.isArray(phoneNumbers) && phoneNumbers.length > 1
    setHasMultiplePhoneNumbers(multiplePhoneNumbers)
    setPageReady(true)
  }

  const handleBack = () => {
    dispatch({ type: 'SET_CURRENT_STEP', payload: 2 })
  }
  const handlePhoneChange = (event) => {
    setSelectedPhoneNumber(event.target.value)
  }

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value)
    if (phoneNumbers.length === 1 && event.target.value === 'phone') {
      setSelectedPhoneNumber(phoneNumbers[0])
    }
  }

  const requestOTPPhoneNumber = async () => {
    try {
      if (selectedMethod === 'phone' && !selectedPhoneNumber) throw new Error('Please select a phone number')
      setLoading(true)

      const response = await requestOTP(session?.mfaOptions?.sessionID, selectedPhoneNumber)

      if (response?.status === 200) {
        setLoading(false)

        toast.success(response.data?.Message)
        dispatch({ type: 'SET_CURRENT_STEP', payload: 3 })
        dispatch({ type: 'SELECTED_PHONE_NUMBER', payload: selectedPhoneNumber })
        return
      }
      setLoading(false)
      toast.error(response.data?.Message || globalMessages.Unable_to_process_your_request)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || globalMessages.Unable_to_process_your_request)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Handles the 'Continue' button click, initiating the OTP request, or moving to security questions.
   * Validates the selected method and uses the appropriate scenario handler.
   */
  const handleClickContinue = () => {
    if (!selectedMethod) {
      toast.error('Please select a method')
      return
    }
    switch (selectedMethod) {
      case 'phone':
        if (hasMultiplePhoneNumbers) {
          setPhoneDialogOpen(true)
        } else {
          requestOTPPhoneNumber()
        }
        break
      case 'security_question':
        dispatch({ type: 'SET_CURRENT_STEP', payload: 4 }) // proceed to security questions
        break
      default:
        toast.error('Invalid method selected')
    }
  }

  return (
    <div data-testid="passcode-verification-container" className={classes.forgotPasswordRoot}>
      <ScrollToTop />
      {
        /* If the page is not ready, show a loading indicator */
        !pageReady ? (
          <CircularProgress />
        ) : (
          <Grid>
            <Box display="flex" flexDirection="column" alignItems="center">
              <RadioGroup name="selected-method" value={selectedMethod} onChange={handleMethodChange}>
                {hasMultiplePhoneNumbers ? (
                  <FormControlLabel
                    className={classes.radioGroup}
                    value="phone"
                    control={<Radio className={classes.customRadio} />}
                    label={`Receive an authentication PIN on your phone number`}
                  />
                ) : (
                  <FormControlLabel
                    className={classes.radioGroup}
                    value="phone"
                    control={<Radio className={classes.customRadio} />}
                    label={`Receive an authentication PIN on your phone number ending in (***) ***-${
                      phoneNumbers[0]?.slice(-4) ?? ''
                    }`}
                  />
                )}
                {/* If the user has no security questions saved disable the security questions, then show the security question option */}
                <FormControlLabel
                  className={classes.radioGroup}
                  disabled={securityQuestions?.length === 0}
                  value="security_question"
                  control={<Radio className={classes.customRadio} />}
                  label="Security Questions"
                />
              </RadioGroup>
            </Box>
            <Box className={classes.disclosure}>
              <ButtonPrimary
                disabled={!selectedMethod || loading}
                stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
                onClick={handleClickContinue}
              >
                Continue
                {loading && <AutorenewIcon className={classes.autoRenew} />}
              </ButtonPrimary>
            </Box>
          </Grid>
        )
      }

      {/* Conditional phone number selection dialog */}
      {isPhoneDialogOpen && (
        <Dialog
          className={classes.dialogRoot}
          open={isPhoneDialogOpen}
          onClose={() => setPhoneDialogOpen(false)}
          PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
          <Box className={classes.iconContainer}>
            <IconButton aria-label="close" onClick={() => setPhoneDialogOpen(false)} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item className={classes.dialogContainer}>
            <DialogTitle className={classes.subTitle}>
              Select from one of your preferred phone number(s) to receive a one time passcode.
            </DialogTitle>
            <DialogContent>
              <RadioGroup aria-label="phone" name="phone" value={selectedPhoneNumber} onChange={handlePhoneChange}>
                {phoneNumbers.map((phone, index) => (
                  <FormControlLabel
                    className={classes.radioGroup}
                    key={index}
                    value={phone}
                    control={<Radio className={classes.customRadio} />}
                    label={`Get code on (***) ***-${phone.slice(-4)}`}
                  />
                ))}
              </RadioGroup>
            </DialogContent>
            <DialogActions className={classes.centeredContent}>
              <ButtonPrimary
                onClick={requestOTPPhoneNumber}
                disabled={loading}
                stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
              >
                Send Code
              </ButtonPrimary>
            </DialogActions>
          </Grid>
        </Dialog>
      )}
    </div>
  )
}

function OTPInputComponent(props) {
  const classes = useStylesForgotPassword()
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const [currentCount, setCount] = useState(0) // Sets a timer for 15 seconds
  const { _, dispatch } = useSession()
  const [disabledButton, setDisabledButton] = useState(false)
  const [otpValue, setOtpValue] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
  const { session, setStep } = props
  const navigate = useNavigate()

  useEffect(() => {
    if (currentCount <= 0) return setIsResendDisabled(false)
    const timer =
      currentCount > 0 &&
      setInterval(() => {
        setCount(currentCount - 1)
      }, 1000)
    return () => clearInterval(timer)
  }, [currentCount])

  /**
   * Checks if the entered OTP is valid.
   * @param {Object} OTPObject - An object containing the OTP values.
   * @returns {boolean} - Returns true if the OTP is invalid (less than 6 digits), false otherwise.
   */
  const checkEnteredOTP = (OTPObject) => {
    let otp = ''
    for (let key in OTPObject) {
      otp += OTPObject[key]
    }
    if (otp.length === 6) {
      return false
    } else {
      return true
    }
  }

  /**
   * Updates the state with the new OTP value when a digit is entered in the OTP input box.
   * @param {Object} event - The event object.
   */

  // /**
  //  * Focuses on the next input box when a digit is entered in the current input box.
  //  * If the current input box is empty and the Backspace or Delete key is pressed, it focuses on the previous input box.
  //  * @param {Object} event - The event object.
  //  */

  const handleOTPChange = (event) => {
    const { name, value } = event.target
    if (!/^[0-9]*$/.test(value)) {
      return
    }
    // Update state with the new value
    setOtpValue((prevState) => ({ ...prevState, [name]: value }))

    // Get all input elements
    const inputs = document.querySelectorAll('input')
    const inputsArray = Array.from(inputs)
    const currentIndex = inputsArray.findIndex((input) => input === event.target)

    if (value && currentIndex < inputsArray.length - 1) {
      // If input is not empty and it's not the last input, move focus to the next field
      inputsArray[currentIndex + 1].focus()
    } else if (!value && currentIndex > 0) {
      // If input is empty and it's not the first input, move focus to the previous field
      inputsArray[currentIndex - 1].focus()
    }
  }

  const handleKeyDown = (event) => {
    const inputs = document.querySelectorAll('input')
    const inputsArray = Array.from(inputs)
    const currentIndex = inputsArray.findIndex((input) => input === event.target)

    // If the Backspace key was pressed and it's not the first input, move focus to the previous input
    if (event.key === 'Backspace' && currentIndex > 0) {
      // Prevent the default action of Backspace if it's about to clear the content. This prevents navigation back.
      if (event.target.value === '') inputsArray[currentIndex - 1].focus()
    }
  }

  /**
   * Returns a string containing the OTP entered by the user.
   * @param {Object} otp - An object containing the OTP values.
   * @returns {string} - A string containing the OTP entered by the user.
   */
  const getPasscode = (otp) => {
    let passcode = ''
    for (let key in otp) {
      passcode += otp[key]
    }
    return passcode
  }

  /**
   * Handles the submit button click event.
   * Validates the entered OTP and navigates to the reset password page if the OTP is valid.
   * @returns {void}
   */

  /**
   * Handles the submit button click event.
   * Calls the API to validate the entered OTP.
   * If the OTP is valid, navigates to the reset password page.
   * @returns {void}
   * @async
   * @throws {Error} - Throws an error if the API call fails.
   */
  const handleClickSubmit = async () => {
    setDisabledButton(true)
    const enteredOTP = getPasscode(otpValue)
    const email = Cookies.get('email')
    try {
      if (!enteredOTP) throw new Error('Please enter 6 digit OTP')
      const response = await validateOTP(enteredOTP, session?.mfaOptions?.sessionID)
      if (response?.data?.result === 'success') {
        toast.success(response.data?.message)
        setDisabledButton(true)
        navigate('/reset-forgot-password', { state: { Email: email } })
      } else {
        if (
          response?.data?.message?.includes('account has been locked') ||
          response?.data?.error?.includes('is locked')
        ) {
          toast.error(globalMessages?.Contact_Support)
          dispatch({ type: 'SET_IS_LOCKED', payload: true })
          navigate('/login')
        } else {
          setOtpValue({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
          toast.error(response.data?.message)
        }
      }
    } catch (error) {
      toast.error(error.message || globalMessages.Unable_to_process_your_request)
    } finally {
      setDisabledButton(false)
    }
  }

  /**
   * Handles the resend button click event.
   * Calls the API to resend the OTP.
   * @returns {void}
   * @async
   * @throws {Error} - Throws an error if the API call fails.
   * @throws {Error} - Throws an error if the API call returns an error message.
   */
  const resendOtpAsync = async () => {
    try {
      setDisabledButton(true)
      setCount(15)
      setIsResendDisabled(true)
      setOtpValue({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' })
      const response = await requestOTP(session?.mfaOptions?.sessionID, session?.selectedPhoneNumber)

      setDisabledButton(false)

      if (response?.status === 200) {
        toast.success(response.data?.Message)
      } else {
        throw new Error(response.data?.Message || globalMessages.Unable_to_process_your_request)
      }
    } catch (error) {
      setDisabledButton(false)
      setIsResendDisabled(false)
      toast.error(error?.message || globalMessages.Unable_to_process_your_request)
    }
  }

  /**
   * This function returns a TextField component for OTP input.
   * @param {string} id - The id of the TextField component.
   * @param {string} name - The name of the TextField component.
   * @param {number} tabIndex - The tabIndex of the TextField component.
   * @returns {JSX.Element} - The OTP TextField component.
   */
  const getOTPTextField = (id, name, tabIndex) => {
    const opt = Object.keys(otpValue)
    return (
      <Grid item id={`wrap${id}`} style={{ margin: '0 5px', position: 'relative' }}>
        <OtpField
          autoFocus={tabIndex === 1}
          id={id}
          name={name}
          value={otpValue[opt[tabIndex - 1]]}
          className={classes.otpNumber}
          lable="OTP"
          type="number"
          variant="outlined"
          onChange={handleOTPChange}
          onKeyDown={handleKeyDown}
          inputProps={{ tabIndex: tabIndex, maxLength: 1 }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1)
          }}
        />
      </Grid>
    )
  }
  const handleBack = () => {
    dispatch({ type: 'SET_CURRENT_STEP', payload: 2 })
  }
  return (
    <div data-testid="passcode-verification-container" className={classes.forgotPasswordRoot}>
      <Link className={classes.arrowBack} onClick={() => handleBack()}>
        <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
      </Link>
      <form>
        {/* disclosure text that falls outside of template */}
        <Typography style={{ color: '#585858', marginTop: '-25px', textAlign: 'center' }}>
          Code is valid for 15 minutes.
        </Typography>
        <Grid
          justifyContent={'center'}
          flexWrap={'unset'}
          className={classes.otpContainer}
          container
          item
          id="otpFieldWrapper"
          style={{ marginTop: '2.5rem' }}
        >
          {getOTPTextField('otpNumberOne', 'otp1', 1)}
          {getOTPTextField('otpNumberTwo', 'otp2', 2)}
          {getOTPTextField('otpNumberThree', 'otp3', 3)}
          {getOTPTextField('otpNumberFour', 'otp4', 4)}
          {getOTPTextField('otpNumberFive', 'otp5', 5)}
          {getOTPTextField('otpNumberSix', 'otp6', 6)}
        </Grid>

        <div className={classes.formContainer}>
          <Typography className={classes.footerText}>
            If you do not receive a code within 2 minutes,{' '}
            {currentCount > 0 ? (
              <Link className={classes.footerLink}>Resend</Link>
            ) : { isResendDisabled } ? (
              <Link className={classes.footerLink} onClick={resendOtpAsync}>
                resend code.
              </Link>
            ) : null}
            {currentCount > 0 ? ` (in 0:${currentCount} secs)` : ''}
          </Typography>

          <Grid item className={classes.footer}>
            <ButtonPrimary
              stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
              disabled={checkEnteredOTP(otpValue) || disabledButton}
              onClick={handleClickSubmit}
            >
              Confirm
            </ButtonPrimary>
          </Grid>
        </div>
      </form>
    </div>
  )
}

function MFASecurityQuestionsComponent() {
  const { state: session, dispatch } = useSession() // Destructure state and dispatch from useSession
  const [selectedQuestions, setSelectedQuestions] = useState()
  const [counter, setCounter] = useState(0)
  const classes = useStylesForgotPassword()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (session?.mfaOptions?.securityQuestions !== undefined) {
      const question = session?.mfaOptions?.securityQuestions
      const questionArray = question[Math.floor(Math.random() * question?.length)]
      setSelectedQuestions(questionArray)
    }
  }, [session])

  //Yup validations for all the input fields
  const validationSchema = yup.object({
    answer: yup
      .string('Enter your answer')
      .trim()
      .max(30, 'Should be maximum of 30 characters')
      .required('Enter your answer'),
  })
  //Form Submission
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answer: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const email = Cookies.get('email')
        setLoading(true)
        const response = await validateRecoveryAnswer(
          selectedQuestions?.question_id,
          values?.answer,
          session?.mfaOptions?.sessionID,
          true
        )
        if (response?.data?.result === 'success') {
          setLoading(false)
          toast.success(response.data?.message)
          dispatch({ type: 'SET_CURRENT_STEP', payload: 2 })
          navigate('/reset-forgot-password', { state: { Email: email } })
        } else {
          if (response?.data?.message.includes('account has been locked')) {
            setLoading(false)
            toast.error(globalMessages?.Contact_Support)
            dispatch({ type: 'SET_IS_LOCKED', payload: true })
            navigate('/login')
          } else {
            setLoading(false)
            toast.error(globalMessages?.Incorrect_response)
          }
        }
        throw new Error(globalMessages.Unable_to_process_your_request)
      } catch (error) {
        setLoading(false)
        toast.error(error)
      } finally {
        setLoading(false)
      }
    },
  })

  const preventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }
  const handleBack = () => {
    dispatch({ type: 'SET_CURRENT_STEP', payload: 2 })
  }
  return (
    <div data-testid="mfa-security-questions-component">
      <ScrollToTop />
      <Grid>
        <Link className={classes.arrowBack} onClick={() => handleBack()}>
          <i className="material-icons dp48 yellowTextNew floatingButton">arrow_back</i>
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column" gap={1} paddingBottom={4}>
            <Typography className={classes.securityQuestion}>Security Question</Typography>
            <Typography style={{ color: '#585858' }}>{selectedQuestions?.question}*</Typography>
            <TextField
              id="Answer"
              name="answer"
              data-testid="security-input"
              design="new"
              type="text"
              variant="outlined"
              fullWidth
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ maxLength: 30 }}
              // onKeyDown={preventSpace}
              error={formik.touched.answer && Boolean(formik.errors.answer)}
              helperText={formik.touched.answer && formik.errors.answer}
            />

            <Typography className={classes.disclosureText}>Answers are not case sensitive</Typography>

            <Typography style={{ visibility: 'hidden', height: '0' }}>
              This is place holder text that allows for consitency across all card components .
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2} paddingBottom={4} alignItems="center">
            <ButtonPrimary
              stylebutton='{"background": "", "color":"" , "fontSize" : "15px", "padding" : "0px 30px", "width":"175px","height":"42px"}'
              type="submit"
              disabled={!formik.values.answer || loading}
            >
              Confirm
            </ButtonPrimary>
          </Box>
        </form>
      </Grid>
    </div>
  )
}

ForgotPasswordOTPPasscode.propTypes = {
  setSelection: PropTypes.func,
  phoneNumberList: PropTypes.array,
}

OTPInputComponent.propTypes = {
  classes: PropTypes.object,
  step: PropTypes.number.isRequired, // Assuming step is a number
  setStep: PropTypes.func.isRequired,
  session: PropTypes?.any,
  dispatch: PropTypes.func.isRequired, // Add this line to validate the dispatch prop
}

SelectMethodComponent.propTypes = {
  classes: PropTypes.object,
}

ValidatingTokenComponent.propTypes = {
  step: PropTypes.number.isRequired, // Assuming step is a number
  setStep: PropTypes.func.isRequired,
  session: PropTypes?.any,
  dispatch: PropTypes.func.isRequired, // Add this line to validate the dispatch prop
}

MFASecurityQuestionsComponent.propTypes = {
  classes: PropTypes.object,
}

ForgotPasswordOTPPasscode.propTypes = {
  classes: PropTypes.object,
}

export default ForgotPasswordOTPPasscode

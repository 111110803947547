import globalMessages from '../../assets/data/globalMessages.json'
import APICall from '../lib/AxiosLib'
import ErrorLogger from '../lib/ErrorLogger'
import getClientIp from './CommonController'
import { encode as base64_encode } from 'base-64'
const utf8 = require('utf8')

/***** To Send the passcode without Authentication*****/
export async function RetrieveCustomerBySSN(ssn, visitorId) {
  try {
    let ipAddress = await getClientIp()
    //API
    let url = 'retrieve_customer_by_ssn'
    let param = ''
    let data = {
      ssn: ssn,
      deviceType: visitorId,
      ip: ipAddress,
    }
    let method = 'POST'
    let addAccessToken = false
    let authId = process.env.REACT_APP_AUTH_ID
    let authPW = process.env.REACT_APP_AUTH_PASSWORD
    let idBytes = utf8.encode(authId)
    let pwBytes = utf8.encode(authPW)
    let idEncoded = base64_encode(idBytes)
    let pwEncoded = base64_encode(pwBytes)

    //API call
    return await APICall(url, param, data, method, addAccessToken, idEncoded, pwEncoded)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Retrieve_Customer_By_SSN_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

/***** To Send the passcode without Authentication*****/
export async function SendLoginPassCodeWithoutAuth(customerPhone) {
  try {
    //API
    let url = 'send_login_passcode_without_auth'
    let param = ''
    let data = {
      deliveryMethod: 'phone',
      customerPhone: customerPhone,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Send_Login_Passcode_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

/***** To verify the login passcode without Authentication *****/
export async function VerifyLoginPassCodeWithoutAuth(passCode, email, deviceType, customerPhone) {
  try {
    //API
    let url = 'verify_login_passcode_without_auth'
    let param = ''
    let data = {
      email: email,
      passCode: passCode,
      deviceType: deviceType,
      customerPhone: customerPhone,
    }
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_Verify_Login_Passcode_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}

/***** To save security answer without Authentication *****/
export async function saveSecurityAnswerWithoutAuth(answerData) {
  try {
    let url = 'save_security_id_answer_without_auth'
    let param = ''
    let data = answerData
    let method = 'POST'
    let addAccessToken = false

    //API call
    return await APICall(url, param, data, method, addAccessToken)
  } catch (error) {
    ErrorLogger(globalMessages.Error_executing_AccountDetails_API, error)
    return globalMessages.Unable_to_process_your_request
  }
}
